//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext, useRef } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import { Context } from "../../common/context/Context";
import "./Job.css";
import Moment from "moment";
import { MyProfileActions } from "../myProfile/MyProfileActions";
import { JobActions } from "./JobActions";
import JobCard from "./JobCard";
import Spinner from "../../common/components/spinner-loader/Spinner";
import Matchsetup from "./matchsetup";
import JobDetails from "./JobDetails";
import { DashboardActions } from "../dashBoard/dashboardActions";
import countryToCurrency from "country-to-currency";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { globalDateFormat } from "../../common/helpers/DateTimeHelper";
import JobCompareModal from "./jobCompare/JobCompareModal";

function JobMatchesTab({
  JDshowHide,
  masterCountryLists,
  matchCMSData,
  jobCardCMSData,
  searchCMSData,
  tabStatus,
  saveUnsaveJobId,
}) {
  const context = useContext(Context);
  const [isMobile, setIsMobile] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(true);
  const [isSaveSearchOpen, setIsSaveSearchOpen] = useState(false);
  const [isCompareJobsOpen, setIsCompareJobsOpen] = useState(false);
  const [saveSearchReferenceElement, setSaveSearchReferenceElement] =
    useState(null);
  const [compareJobsButtonRef, setCompareJobsButtonRef] = useState(null);
  const [extractMatchQuery, setExtractMatchQuery] = useState("");
  const [preferredName, setPreferredName] = useState();
  const [resultCount, setResultCount] = useState("");
  const [jobLoader, setJobLoader] = useState(false);
  const [screenLoader, setScreenLoader] = useState(false);
  /* Job match Notification constants */
  const [parsedJSON, setParsedJSON] = useState();
  const [notificationUpdated, setNotificationUpdated] = useState(false);
  const [notificationModal, setNotificationModal] = useState(false);
  const [hasOpted, setHasOpted] = useState(false);
  const [isDaily, setIsDaily] = useState(false);
  const [notificationPref, setNotificationPref] = useState(false);
  const [firstResume, setFirstResume] = useState(true);

  const [hasOptedPrev, setHasOptedPrev] = useState(false);
  const [isDailyPrev, setIsDailyPrev] = useState(false);

  const [filteredCountryList, setFilteredCountryList] = useState();
  const [countryMasterListNotification, setCountryMasterListNotification] =
    useState([]);
  const [isCountryNotification, setIsCountryNotification] = useState(false);
  const [countryListsNotification, setCountryListsNotification] = useState([]);
  const [selectedCountryNotification, setSelectedCountryNotification] =
    useState();
  const [selectedCountryCodeNotification, setSelectedCountryCodeNotification] =
    useState();
  const [isLocationNotification, setIsLocationNotification] = useState(false);
  const [locationListsNotification, setLocationListsNotification] = useState(
    []
  );
  const [selectedLocationNotification, setSelectedLocationNotification] =
    useState();
  const [city, setCity] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [masterContractType, setMasterContractType] = useState([]);
  const [masterEmploymentType, setMasterEmploymentType] = useState([]);
  const [masterSalaryRange, setMasterSalaryRange] = useState([]);
  const [newResumeAlert, setNewResumeAlert] = useState(false);
  const [parsedString, setParsedString] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [jobTitleState, setJobTitleState] = useState("");
  const [resumeIndex, setResumeIndex] = useState(0);
  const [allResumeData, setAllResumeData] = useState([]);
  const [editTitleModal, setEditTitleModal] = useState(false);
  const [deleteProfileModal, setDeleteProfileModal] = useState(false);
  const [jobProfileNameErrorMsg, setJobProfileNameErrorMsg] = useState("");
  const [filterResultForm, setFilterResultForm] = useState({
    isFilterApplied: false,
    applyButtonStatus: true,
    salary: {
      name: "salary",
      concatValue: "",
      value: [],
    },
    company: {
      name: "company",
      concatValue: "",
      value: "",
    },
    contractType: {
      name: "contractType",
      concatValue: "",
      value: [],
    },
    employmentType: {
      name: "employmentType",
      concatValue: "",
      value: [],
    },
  });

  const [sortByValue, setSortByValue] = useState('_score');
  const [sortByValues, setSortByValues] = useState([]);
  const [isSortOpen, setIsSortOpen] = useState(false);
  //const [defaultSortOption, setDefaultSortOption] = useState('Relevance');

  const[searchInput, setSearchInput] = useState ('');

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);

  const appInsights = useAppInsightsContext();

  const toggleDropdown = () => {
    setIsSortOpen(!isSortOpen);
    setDropdownOpen(!dropdownOpen);
  };
  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsSortOpen(false);
    setDropdownOpen(false); // Close dropdown after selection
    sortByFilter(option);
  };

  const [candidateLastLoginData, setCandidateLastLoginData] = useState("");
  // Get candidate last login
  const fetchCandidateLastLogin = async () => {
    await DashboardActions.GetLastSession().then(
      (res) => {
        if (res.status === 200) {
          setCandidateLastLoginData(res?.data?.loginDate);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  useEffect(() => {
    fetchCandidateLastLogin();
  }, [candidateLastLoginData]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsSortOpen(false);
      setDropdownOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const getManpowerLogo = (key) => {
    let imageList =
      jobCardCMSData &&
      jobCardCMSData.pageContent &&
      jobCardCMSData.pageContent.ImageList &&
      jobCardCMSData.pageContent.ImageList;
    let imageURL = "";
    if (imageList) {
      imageList.map((x) => {
        if (x.Key === key) {
          imageURL = x.ImageURL;
        }
      });
    }
    return imageURL;
  };

  const fetchJobCardCMSData = (key) => {
    let label = "";
    if (jobCardCMSData?.pageContent?.Labels) {
      jobCardCMSData?.pageContent?.Labels.map((x) => {
        if (x.Key === key) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  useEffect(() => {
    const handleResize = () => {
      setTimeout(() => {
        setIsMobile(window.innerWidth < Cirrus.devices.Desktop);
        setIsFilterOpen(window.innerWidth > Cirrus.devices.Tablet);
      }, 250);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (masterCountryLists.length) {
      setCountryMasterListNotification(masterCountryLists);
      masterCountryLists?.map((country, id) => {
        const list = masterCountryLists?.map((country) => country.name);
        setCountryListsNotification(list);
      });
    }
  }, [masterCountryLists]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (tabStatus.matchesTab === true) {
      fnLoadPersonalInfoData();
      checkResumeUploadStatus();
    }
    TrackActions.PageViewRecord(appInsights, "Job Match");
    TrackActions.PageAction("Job Match");
  }, []);

  // useEffect(() => {
  //     checkResumeUploadStatus();
  // }, [resumeIndex])

  const fnLoadPersonalInfoData = async () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    try {
      let Profilejson = {};
      if (candidateID != "") {
        const res = await MyProfileActions.GetcandidateProfile(candidateID);
        if (res.status === 200) {
          Profilejson = res.data;
          if (res?.data?.preferredName !== "") {
            setPreferredName(Profilejson.preferredName);
          } else {
            setPreferredName(res?.data?.firstName);
          }
        } else {
          Profilejson = {};
        }
      } else {
        Profilejson = {};
      }
      return Profilejson;
    } catch (error) {
      return {};
    }
  };

  const fetchJobGreetingsLabel = (Key) => {
    if (matchCMSData.pageContent["JobSearch.JobGreetings"]) {
      let refJobGreetingsLabel = JSON.parse(
        JSON.stringify(matchCMSData.pageContent["JobSearch.JobGreetings"])
      );
      let label = "";
      if (refJobGreetingsLabel?.Labels) {
        refJobGreetingsLabel?.Labels.map((x) => {
          if (x.Key === Key) {
            label = x.Phrase;
          }
        });
      }
      return label;
    }
  };

  const fetchJobMatchModalLabel = (Key) => {
    if (matchCMSData.pageContent["JobSearch.JobMatchSetUpModal"]) {
      let refJobMatchModalLabel = JSON.parse(
        JSON.stringify(matchCMSData.pageContent["JobSearch.JobMatchSetUpModal"])
      );
      let label = "";
      if (refJobMatchModalLabel?.Labels) {
        refJobMatchModalLabel?.Labels.map((x) => {
          if (x.Key === Key) {
            label = x.Phrase;
          }
        });
      }
      return label;
    }
  };

  const fetchGetStartedResumeLabels = (Key) => {
    if (matchCMSData.pageContent["JobSearch.NoResumeUploaded"]) {
      let refJobGreetingsLabel = JSON.parse(
        JSON.stringify(matchCMSData.pageContent["JobSearch.NoResumeUploaded"])
      );
      let label = "";
      if (refJobGreetingsLabel?.Labels) {
        refJobGreetingsLabel?.Labels.map((x) => {
          if (x.Key === Key) {
            label = x.Phrase;
          }
        });
      }
      return label;
    }
  };

  const fetchCommonLabel = (Key) => {
    if (matchCMSData.pageContent["CommonComponent"]) {
      let refCommonComponentLabel = JSON.parse(
        JSON.stringify(matchCMSData.pageContent["CommonComponent"])
      );
      let label = "";
      if (refCommonComponentLabel?.Labels) {
        refCommonComponentLabel?.Labels.map((x) => {
          if (x.Key === Key) {
            label = x.Phrase;
          }
        });
      }
      return label;
    }
  };

  const [savedjobs, setSavedjobs] = useState([]);
  const [appliedjobs, setAppliedjobs] = useState([]);
  const [paginationState, setPaginationState] = useState({
    desabledLoadMore: false,
    searchCountAndLabel: "",
    totalCounts: 0,
    fetchedCounts: 0,
    shownCounts: 0,
    payload: {},
  });
  const [fetchedJobs, setFetchedJobs] = useState([]);
  const [shownJobs, setShownJobs] = useState([]);

  useEffect(() => {
    if (saveUnsaveJobId && saveUnsaveJobId.jobId !== "") {
      let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
      const jobIdList = refShownJobs && refShownJobs.map((job) => job.id);
      if (jobIdList && jobIdList.includes(saveUnsaveJobId.jobId)) {
        const objIndex = refShownJobs.findIndex(
          (obj) => obj.id == saveUnsaveJobId.jobId
        );
        const updated = refShownJobs
          ? (refShownJobs[objIndex].isSaved = false)
          : "";
        setShownJobs(refShownJobs);
      }
    }
  }, [saveUnsaveJobId]);

  const checkResumeUploadStatus = async () => {
    setExtractMatchQuery("");
    await JobActions.ResumeUploadStatus().then(
      (res) => {
        if (res.status === 200 && res.data.length > 0) {
          setAllResumeData(res?.data);
          let index;
          if (context.isEditExistingClicked || context.isResumeBuilder) {
            index = selectedTab;
          } else {
            index = res.data.length - 1;
          }
          setSelectedTab(index);
          context.setIsResumeUploaded(true);
          if (res.data[index]?.extractMatchQuery !== "") {
            setJobLoader(true);
            setParsedString(res.data[index].parseResponseFile);
            let parseJSON = JSON.parse(atob(res.data[index].parseResponseFile));
            setParsedJSON(parseJSON);
            parseJSON?.map((parse) => {
              setCity(parse.contact_details.city);
              setNotificationUpdated(!notificationUpdated);
              setSelectedCountryNotification(
                parse.contact_details.country_description
              );
              setSelectedCountryCodeNotification(
                parse.contact_details.country_code?.toUpperCase()
              );
              setSelectedLocationNotification(parse.contact_details.city);
            });
            context.setDocumentId(res.data[index].id);
            context.setDocumentIdentifier(res.data[index].documentIdentifier);
            setExtractMatchQuery(res.data[index].extractMatchQuery);

            //when more than 1 resume is uploaded, we get notificationPreference from resume API and set it here
            //console.log(res.data[index]);
            //console.log('notificationPreference from resume API : '+res.data[index].notificationPreference);
            setNotificationPref(res.data[index].notificationPreference);
            setFirstResume(false);

          } else {
            setJobLoader(false);
            context.setIsResumeUploaded(false);
            if (context.dashboardResumeFlag) {
              context.setIsGetStartedClicked(true);
            } else {
              context.setIsGetStartedClicked(false);
            }
            context.setDashboardResumeFlag(false);
            JobActions.RevertCV(res?.data?.documentIdentifier).then(
              (resp) => {
                if (resp.status === 200) {
                }
              },
              (err) => {
                console.log(err);
              }
            );
          }
          JobActions.JobsAlert().then((result) => {
            setHasOpted(result.data.hasOpted);
            setIsDaily(result.data.isDaily);
          });
        } else {
          setJobLoader(false);
          context.setIsResumeUploaded(false);
          if (context.dashboardResumeFlag) {
            context.setIsGetStartedClicked(true);
          } else {
            context.setIsGetStartedClicked(false);
          }
          context.setDashboardResumeFlag(false);
        }
      },
      (err) => {
        setJobLoader(false);
        console.log(err);
      }
    );
  };

  const getSalary = (salary, frequency) => {
    let countryCode =
      selectedCountryCodeNotification && selectedCountryCodeNotification;
    context.setCountryToSearch(
      selectedCountryCodeNotification && selectedCountryCodeNotification
    );
    let currencyCode = countryToCurrency[countryCode && countryCode];
    const currencySymbol = getCurrencySymbol(countryCode, currencyCode);
    let formattedSalary =
      salary && frequency ? currencySymbol + salary + " " + frequency : "";
    return formattedSalary;
  };

  function getCurrencySymbol(locale, currency) {
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  }

  useEffect(() => {
    if (extractMatchQuery !== "") {
      if (Object.keys(matchCMSData.pageContent).length !== 0) {
        let candidateID =
          context?.idTokenClaims?.signupId != undefined
            ? context?.idTokenClaims?.signupId
            : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
                "signupId"
              ];
        let editedExtractQuery, Original;
        const documentDate = candidateLastLoginData
          ?.toLocaleString("yyyy-MM-dd")
          .slice(0, 10);
        // let editedExtractQuery = extractMatchQuery + " " + "documentdate:>=today-42" + " " + `city:\"${city}\"` + " " + `jf_source_country:${selectedCountryCodeNotification.toUpperCase()}`;
        // let Original = extractMatchQuery + " " + "documentdate:>=today-42";
        if (candidateLastLoginData) {
          editedExtractQuery =
            extractMatchQuery +
            " " +
            "documentdate:>=" +
            documentDate +
            " " +
            `city:\"${city}\"` +
            " " +
            `jf_source_country:${selectedCountryCodeNotification?.toUpperCase()}`;
          Original = extractMatchQuery + " " + "documentdate:>=" + documentDate;
        } else {
          editedExtractQuery =
            extractMatchQuery +
            " " +
            "documentdate:>=today-42" +
            " " +
            `city:\"${city}\"` +
            " " +
            `jf_source_country:${selectedCountryCodeNotification?.toUpperCase()}`;
          Original = extractMatchQuery + " " + "documentdate:>=today-42";
        }

        let matchedJobPayload = {
          Querystring: city ? editedExtractQuery : Original,
          country: JSON.parse(
            window.sessionStorage.getItem("setProgramDetails")
          )["programCountryCode"],
          searchafterscore: "",
          searchafter: "",
          searchafterdocid: "",
          searchafter2: "",
          language: "en",
          OwnerId: candidateID,
          isFlagsRequired: true,
          field: sortByValue,
          order: 'DESCENDING'
        };
                getAllMatchedjobs(matchedJobPayload, false);
      }
    }
  }, [matchCMSData, notificationUpdated, sortByValue]);

  useEffect(() => {
    if (extractMatchQuery !== "") {
      let candidateID =
        context?.idTokenClaims?.signupId != undefined
          ? context?.idTokenClaims?.signupId
          : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
              "signupId"
            ];
      let editedExtractQuery, Original;
      const documentDate = candidateLastLoginData
        ?.toLocaleString("yyyy-MM-dd")
        .slice(0, 10);
      // let editedExtractQuery = extractMatchQuery + " " + "documentdate:>=today-42" + " " + `city:\"${city}\"` + " " + `jf_source_country:${selectedCountryCodeNotification.toUpperCase()}`;
      // let Original = extractMatchQuery + " " + "documentdate:>=today-42";
      if (candidateLastLoginData) {
        editedExtractQuery =
          extractMatchQuery +
          " " +
          "documentdate:>=" +
          documentDate +
          " " +
          `city:\"${city}\"` +
          " " +
          `jf_source_country:${selectedCountryCodeNotification?.toUpperCase()}`;
        Original = extractMatchQuery + " " + "documentdate:>=" + documentDate;
      } else {
        editedExtractQuery =
          extractMatchQuery +
          " " +
          "documentdate:>=today-42" +
          " " +
          `city:\"${city}\"` +
          " " +
          `jf_source_country:${selectedCountryCodeNotification?.toUpperCase()}`;
        Original = extractMatchQuery + " " + "documentdate:>=today-42";
      }
      let matchedJobPayload = {
        //Querystring: city ? editedExtractQuery : Original,
        Querystring: editedExtractQuery,
        country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
          "programCountryCode"
        ],
        searchafterscore: "",
        searchafter: "",
        searchafterdocid: "",
        searchafter2: "",
        language: "en",
        OwnerId: candidateID,
        isFlagsRequired: true,
        field: sortByValue,
        order: 'DESCENDING'
      };
      getAllMatchedjobs(matchedJobPayload, false);
    }
  }, [extractMatchQuery]);

  const [selectedTab, setSelectedTab] = useState(0);
  const loadJobMatchByIndex = (index) => {
    setSelectedTab(index);
    setParsedString(allResumeData[index].parseResponseFile);
    let parseJSON = JSON.parse(atob(allResumeData[index].parseResponseFile));
    setParsedJSON(parseJSON);
    parseJSON?.map((parse) => {
      setCity(parse.contact_details.city);
      setNotificationUpdated(!notificationUpdated);
      setSelectedCountryNotification(parse.contact_details.country_description);
      setSelectedCountryCodeNotification(
        parse.contact_details.country_code?.toUpperCase()
      );
      setSelectedLocationNotification(parse.contact_details.city);
    });
    context.setDocumentId(allResumeData[index].id);
    context.setDocumentIdentifier(allResumeData[index].documentIdentifier);
    setExtractMatchQuery(allResumeData[index].extractMatchQuery);
  };

  const getAllMatchedjobs = async (payload, loadMoreFlag) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let refPaginationState = JSON.parse(JSON.stringify(paginationState));
    if (!loadMoreFlag) {
      setJobLoader(true);
    }
    let allJobsList = [];
    let allSaveJobList = [...savedjobs];
    let allAppliedJobList = [...appliedjobs];
    let paginationPayload = {};
    await JobActions.GetMatchedjobs(payload).then(
      (res) => {
        console.log(res);
        if (res.status === 200) {
          console.log(res);
          if (res?.data[0].search_info.result_count > 0) {
            res?.data?.map((job) => {
              const matchPercent = job?.score?.score * 100;
              let compName = job?.organisation?.organisation_name;
              let jobObj = {
                companyName: job?.organisation?.organisation_name,
                contractType: job?.contract_type?.contract_type,
                description: job?.job_overview?.job_description,
                id: job?.job_overview?.jobid,
                isComparable: true,
                isFeaturedText: "Featured listing",
                isMatchText: fetchJobCardCMSData(
                  "JobSearch.JobCard.StrongMatch"
                ),
                isSaveable: true,
                isSaved: false,
                jobRole: job?.job_overview?.jobtitle,
                location: job?.job_location?.city,
                onClick: {},
                onComparingButtonClick: {},
                onSaveButtonClick: {},
                posted: convertPostedDate(job?.job_overview?.job_posted_date),
                postedTimeStamp: job?.job_overview?.job_posted_date,
                jobUrl: job?.job_overview?.job_url,
                salary: getSalary(
                  job?.salary?.maximum_value,
                  job?.salary?.frequency
                ),
                lastUpdatedDate:
                  job && job.job_overview && job.job_overview.job_last_updated
                    ? job.job_overview.job_last_updated
                    : null,
                applicationDeadline:
                  job &&
                  job.job_overview &&
                  job.job_overview.application_deadline
                    ? globalDateFormat(job.job_overview.application_deadline)
                    : null,
                startDate:
                  job && job.job_overview && job.job_overview.job_start_date
                    ? globalDateFormat(job.job_overview.job_start_date)
                    : "Flexible Start",
                matchPercentage: job?.score?.score,
                staticCopy: {
                  CollapseJobInfo: "Hide job info",
                  CompanyName: "Company",
                  Compare: fetchCMSData(
                    "JobSearch.CompareJobs",
                    "Job.JobSearch.CompareJobs.Compare"
                  ),
                  ExpandJobInfo: "View job info",
                  IsCompared: "Compare this job",
                  IsFeatured: "This role is featured",
                  IsNotCompared: "Remove this job from comparison",
                  IsSaved: "Unsave this job",
                  IsUnsaved: "Save this job",
                  JobDescription: "Job Description",
                  JobInfo: fetchJobCardCMSData(
                    "JobSearch.JobCard.JobCardMoreInfo"
                  ),
                  JobRole: "Role",
                  JobType: "Type:",
                  Location: "Location",
                  Salary: "Salary:",
                  Save: fetchJobCardCMSData("JobSearch.JobCard.Save"),
                  StartDate: "StartDate",
                },
              };
              if (matchPercent >= 70) {
                jobObj = {
                  ...jobObj,
                  matchPercentage: matchPercent,
                };
              }
              if (compName && compName.includes("Manpower")) {
                jobObj = {
                  ...jobObj,
                  companyLogo: {
                    src: getManpowerLogo("Manpower Logo"),
                    altText: "",
                  },
                };
              }
              allJobsList.push(jobObj);
            });

            let searchAfter2 = '';
            if(sortByValue == '_score') 
              searchAfter2 = res?.data[0].search_info.search_after_score
            else if(sortByValue == 'documentdate') 
              searchAfter2 = res?.data[0].search_info.search_after
            console.log ('getAllMatchedjobs, searchAfter2 and sortByValue : '+searchAfter2 + ' , ' + sortByValue);

            paginationPayload = {
              Querystring: payload.Querystring,
              country: payload.country,
              searchafterscore: res?.data[0].search_info.search_after_score,
              searchafter: res?.data[0].search_info.search_after,
              searchafterdocid: res?.data[0].search_info.search_after_docid,
              searchafter2: searchAfter2,
              language: payload.language,
              OwnerId: payload.OwnerId,
              isFlagsRequired: true,
              field: sortByValue,
              order: 'DESCENDING'
            };
            refPaginationState.searchCountAndLabel = fetchJobGreetingsLabel(
              "Job.JobMatches.JobGreetings.NumberOfMatches"
            );
            refPaginationState.searchCountAndLabel =
              refPaginationState.searchCountAndLabel?.replace(
                "#NUMBER",
                res?.data[0].search_info.result_count
              );
            refPaginationState.totalCounts =
              res?.data[0].search_info.result_count;
          } else {
            allJobsList = [];
            setFetchedJobs([]);
            refPaginationState.searchCountAndLabel = fetchJobGreetingsLabel(
              "Job.JobMatches.JobGreetings.NumberOfMatches"
            );
            refPaginationState.searchCountAndLabel =
              refPaginationState.searchCountAndLabel.replace(
                "#NUMBER",
                res?.data[0].search_info.result_count
              );
            refPaginationState.totalCounts =
              res?.data[0].search_info.result_count;
          }
        } else {
          setJobLoader(false);
          refPaginationState.searchCountAndLabel = fetchJobGreetingsLabel(
            "Job.JobMatches.JobGreetings.NumberOfMatches"
          );
          refPaginationState.searchCountAndLabel =
            refPaginationState.searchCountAndLabel.replace("#NUMBER", "0");
          refPaginationState.totalCounts = "0";
        }
      },
      (err) => {
        console.log(err);
        setJobLoader(false);
      }
    );
    if (allJobsList.length > 0 && !loadMoreFlag) {
      await JobActions.GetSavedJobs(candidateID).then(
        (resp) => {
          if (resp.status === 200) {
            resp?.data.map((ele1) => {
              ele1["id"] = ele1.jobId;
            });
            allSaveJobList = resp?.data;
            setSavedjobs(resp?.data);
          } else {
            setJobLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setJobLoader(false);
        }
      );
      await JobActions.GetAppliedJobs().then(
        (resp) => {
          if (resp.status === 200) {
            resp?.data.map((ele1) => {
              ele1["id"] = ele1.jobId;
            });
            allAppliedJobList = resp?.data;
            setAppliedjobs(resp?.data);
          } else {
            setJobLoader(false);
          }
        },
        (err) => {
          console.log(err);
          setJobLoader(false);
        }
      );
    }

    let jobsWithSaveStatus = [];
    let allSavedJobsID = [];
    allSaveJobList?.map((allSaved) => {
      allSavedJobsID.push(allSaved.id);
    });
    jobsWithSaveStatus = allJobsList.map((ele1) => {
      if (allSavedJobsID.includes(ele1.id)) {
        ele1.isSaved = true;
        ele1.staticCopy.Save = fetchJobCardCMSData(
          "JobSearch.JobCard.SavedButton"
        );
      }
      return ele1;
    });

    let allAppliedJobsID = [];
    allAppliedJobList?.map((allApplied) => {
      allAppliedJobsID.push(allApplied.id);
    });
    jobsWithSaveStatus = allJobsList.map((ele1) => {
      if (allAppliedJobsID.includes(ele1.id)) {
        ele1["isApplied"] = true;
      } else {
        ele1["isApplied"] = false;
      }
      return ele1;
    });

    if (loadMoreFlag) {
      refPaginationState.fetchedCounts =
        refPaginationState.fetchedCounts + jobsWithSaveStatus.length;
      refPaginationState.shownCounts =
        jobsWithSaveStatus.length > 0 ? refPaginationState.shownCounts + 10 : 0;
      refPaginationState.payload =
        jobsWithSaveStatus.length > 0 ? paginationPayload : {};
      setPaginationState(refPaginationState);
      setFetchedJobs(
        jobsWithSaveStatus.length > 0
          ? [...fetchedJobs, ...jobsWithSaveStatus]
          : []
      );
      let sc =
        jobsWithSaveStatus.length > 0 ? jobsWithSaveStatus.slice(0, 10) : [];
      setShownJobs(jobsWithSaveStatus.length > 0 ? [...shownJobs, ...sc] : []);
    } else {
      refPaginationState.fetchedCounts = jobsWithSaveStatus.length;
      refPaginationState.shownCounts = jobsWithSaveStatus.length > 0 ? 10 : 0;
      refPaginationState.payload =
        jobsWithSaveStatus.length > 0 ? paginationPayload : {};
      setPaginationState(refPaginationState);
      setFetchedJobs(jobsWithSaveStatus.length > 0 ? jobsWithSaveStatus : []);
      let sc =
        jobsWithSaveStatus.length > 0 ? jobsWithSaveStatus.slice(0, 10) : [];
      setShownJobs(jobsWithSaveStatus.length > 0 ? sc : []);
    }
    setJobLoader(false);
  };

  const saveJobHandler = async (job) => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
    const savedJobsId = savedjobs && savedjobs.map((job) => job.id);
    const inputJobId = job && job.id && job.id;
    let saveJobPayload = {
      OwnerId: candidateID,
      OwnerType: "Candidate",
      JobId: job?.id,
      JobTitle: job?.jobRole != undefined ? job?.jobRole : "",
      CompanyName: job?.companyName != undefined ? job?.companyName : "",
      JobUrl: job?.jobUrl != undefined ? job?.jobUrl : "",
      IsFeaturedJob: 1,
      IsSaved: savedJobsId.includes(inputJobId) ? 0 : 1,
      IsBookmarked: savedJobsId.includes(inputJobId) ? 0 : 1,
      IsDeleted: 0,
      CreatedBy: "Admin",
      MatchPercentage: job?.matchPercent != undefined ? job?.matchPercent : "",
      JobLocation: job?.location != undefined ? job?.location : "",
      Salary: job?.salary != undefined ? job?.salary : "",
      EmploymentType: job?.contractType != undefined ? job?.contractType : "",
      UpdatedBy: candidateID,
      JobDescription: job?.description != undefined ? job?.description : "",

      JobPostedTimeStamp:
        job?.postedTimeStamp != undefined
          ? Moment(job?.postedTimeStamp).format("MM-DD-YYYY")
          : "",
      LastSavedTimeStamp: Moment(new Date()).format("MM-DD-YYYY"),
      // "JobStartTimeStamp": job?.startDate === "Flexible Start" ? null : job?.startDate,
      CreatedTimeStamp: Moment(new Date()).format("MM-DD-YYYY"),
      LastUnsavedTimeStamp: savedJobsId.includes(inputJobId)
        ? Moment(new Date()).format("MM-DD-YYYY")
        : null,
      UpdatedTimeStamp:
        job?.lastUpdatedDate != undefined
          ? Moment(job?.lastUpdatedDate).format("MM-DD-YYYY")
          : "",
    };
    await JobActions.PostSaveJob(candidateID, saveJobPayload).then(
      (resp) => {
        if (resp.status === 200) {
          refShownJobs.map((x) => {
            if (x.id === inputJobId) {
              if (savedJobsId.includes(x.id)) {
                x.isSaved = false;
                x.staticCopy.Save = fetchJobCardCMSData(
                  "JobSearch.JobCard.Save"
                );
                var filtered = savedjobs.filter(function (job) {
                  return job.id != x.id;
                });
                setSavedjobs(filtered);
              } else {
                x.isSaved = true;
                x.staticCopy.Save = fetchJobCardCMSData(
                  "JobSearch.JobCard.SavedButton"
                );
                savedjobs.push(x);
                setSavedjobs(savedjobs);
              }
            }
            return x;
          });
          setShownJobs(refShownJobs);
        } else {
          setJobLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setJobLoader(false);
      }
    );
  };

  const loadMoreJobHandler = () => {
    const documentDate = candidateLastLoginData
      ?.toLocaleString("yyyy-MM-dd")
      .slice(0, 10);
    let refPaginationState = JSON.parse(JSON.stringify(paginationState));
    if (refPaginationState.shownCounts >= refPaginationState.fetchedCounts) 
    {
      let searchAfter2 = '';
      if(sortByValue == '_score') 
        searchAfter2 = refPaginationState.payload.searchafterscore
      else if(sortByValue == 'documentdate') 
        searchAfter2 = refPaginationState.payload.searchafter
      console.log ('loadMoreJobHandler, searchAfter2 and sortByValue : '+searchAfter2 + ' , ' + sortByValue);

      let payload = {
        Querystring:
          refPaginationState.payload.Querystring,
          // +
          // " " +
          // "documentdate:>=" +
          // (candidateLastLoginData ? documentDate : "today-42") +
          // " " +
          // `city:\"${city}\"` +
          // " " +
          // `jf_source_country:${selectedCountryCodeNotification?.toUpperCase()}`
        country: refPaginationState.payload.country,
        searchafterscore: refPaginationState.payload.searchafterscore,
        searchafter: refPaginationState.payload.searchafter,
        searchafterdocid: refPaginationState.payload.searchafterdocid,
        searchafter2: searchAfter2,
        language: refPaginationState.payload.language,
        OwnerId: refPaginationState.payload.OwnerId,
        isFlagsRequired: true,
        field: sortByValue,
        order: 'DESCENDING'
      };
      refPaginationState.desabledLoadMore = true;
      setPaginationState(refPaginationState);
      console.log('calling getAllMatchedjobs from loadMoreJobHandler');
      console.log(payload);
      getAllMatchedjobs(payload, true);
    } else {
      let refFetchedJobs = JSON.parse(JSON.stringify(fetchedJobs));
      let sc = refFetchedJobs.slice(
        refPaginationState.shownCounts,
        refPaginationState.shownCounts + 10
      );
      setShownJobs([...shownJobs, ...sc]);
      refPaginationState.shownCounts = refPaginationState.shownCounts + 10;
      setPaginationState(refPaginationState);
    }
  };

  const convertPostedDate = (date) => {
    let postDate = date && date;
    let todayDate = new Date();
    postDate = Moment(postDate).format("MM-DD-YYYY");
    todayDate = Moment(todayDate).format("MM-DD-YYYY");
    function parseDate(str) {
      var date = str && str.split("-");
      return new Date(date && date[2], date && date[0] - 1, date && date[1]);
    }
    function dateDiff(first, second) {
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }
    let posted_Date = dateDiff(parseDate(postDate), parseDate(todayDate));
    let postedDate;
    if (posted_Date < 1) {
      // postedDate = fetchJobCardCMSData("JobSearch.JobCard.Hoursago").replace(
      //   "#TIME#",
      //   posted_Date
      // );
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.Today");
    } else if (posted_Date >= 1 && posted_Date <= 30) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.Day").replace(
        "#TIME#",
        posted_Date
      );
    } else if (posted_Date > 30 && posted_Date <= 60) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.1Month").replace(
        "#TIME#",
        posted_Date - 30
      );
    } else if (posted_Date > 60 && posted_Date <= 90) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.2Month").replace(
        "#TIME#",
        posted_Date - 60
      );
    } else if (posted_Date > 90 && posted_Date <= 180) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.3Month");
    } else if (posted_Date > 180) {
      postedDate = fetchJobCardCMSData("JobSearch.JobCard.6Month");
    }
    postDate =
      fetchJobCardCMSData("JobSearch.JobCard.Posted") + " " + postedDate;
    return postDate;
  };

  /* Job Match Notification related functions Begins */
  const countryRef = useRef(null);
  function useOutsideCountryListClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutsideCountryList(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setIsCountryNotification(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutsideCountryList);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener(
          "mousedown",
          handleClickOutsideCountryList
        );
      };
    }, [ref]);
  }
  useOutsideCountryListClick(countryRef);

  const locationRef = useRef(null);
  function useOutsideLocationListClick(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutsideLocationList(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsLocationNotification(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutsideLocationList);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener(
          "mousedown",
          handleClickOutsideLocationList
        );
      };
    }, [ref]);
  }
  useOutsideLocationListClick(locationRef);
  const notificationHandler = () => {
    setNotificationModal(true);
  };
  const notificationModalExit = () => {
    setNotificationModal(false);
  };
  const editTitleModalExit = () => {
    setEditTitleModal(false);
    setJobTitleState("");
  };
  const deleteProfileModalExit = () => {
    setDeleteProfileModal(false);
  };
  const notify = (e) => {
    if (e.target.checked) {
      setHasOpted(true);
    } else {
      setHasOpted(false);
    }
  };
  const checkedDaily = (e) => {
    if (e.target.checked) {
      setIsDaily(true);
    }
  };
  const checkedWeekly = (e) => {
    if (e.target.checked) {
      setIsDaily(false);
    }
  };

  const editJobMatchHandler = () => {
    context.setIsParsed(false);
    context.setIsResumeUploaded(false);
    context.setIsGetStartedClicked(true);
    context.setHideResumeUpload(true);
    setParsedString(allResumeData[selectedTab].parseResponseFile);
    context.setDocumentId(allResumeData[selectedTab].id);
    context.setDocumentIdentifier(
      allResumeData[selectedTab].documentIdentifier
    );
    context.setIsEditExistingClicked(true);

    //console.log(allResumeData[selectedTab].hasOpted);
    //Edit Existing values for Job Match Notifications
    //console.log('from resume API editJobMatchHandler, HasOptedPrev and IsDailyPrev : '+allResumeData[selectedTab].hasOpted 
    //              + ' , '+ allResumeData[selectedTab].isDaily);
    setHasOptedPrev (allResumeData[selectedTab].hasOpted);
    setIsDailyPrev (allResumeData[selectedTab].isDaily)
  };

  const newResumeAlertHandler = () => {
    context.setIsEditExistingClicked(false);
    setNewResumeAlert(true);
  };

  const uploadNewHandler = (flag) => {
    console.log(flag);
    context.setIsEditExistingClicked(false);
    context.setIsResumeBuilder(flag);
    context.setIsParsed(false);
    setNewResumeAlert(false);
    context.setIsResumeUploaded(false);
    context.setIsGetStartedClicked(true);
    context.setHideResumeUpload(true);
    context.setNewUploadModal(true);
  };

  const [editIndex, setEditIndex] = useState(0);
  const jobTitleRef = useRef(null);
  const editTitleHandler = (index) => {
    setEditIndex(index);
    setJobTitle(getFirstLetterOfEveryWord(allResumeData[index]?.jobTitle));
    setJobProfileName(allResumeData[index].jobTitle);
    setEditTitleModal(true);
  };

  const [deleteIndex, setDeleteIndex] = useState(0);
  const deleteProfileHandler = (index) => {
    context.setIsEditExistingClicked(false);
    context.setIsResumeBuilder(false);
    setDeleteIndex(index);
    setDeleteProfileModal(true);
  };

  const [jobProfileName, setJobProfileName] = useState("");
  const onChangeJobTitle = (input) => {
    setJobProfileName(input);
    setJobTitleState("");
  };

  const updateJobTitle_ButtonClick = () => {
    const jobProfile_AllowedCharactors = /^[a-zA-Z0-9-@ ]+$/;

    if (!jobProfileName) {
      setJobProfileNameErrorMsg(
        fetchJobGreetingsLabel(
          "Job.JobMatches.JobGreetings.ValidationMsgEmptyProfileName"
        )
      );
      setJobTitleState("error");
    } else if (
      jobProfileName.length > 25 ||
      !jobProfile_AllowedCharactors.test(jobProfileName)
    ) {
      setJobProfileNameErrorMsg(
        fetchJobGreetingsLabel(
          "Job.JobMatches.JobGreetings.ValidationMsgLengthMatchProfileName"
        )
      );
      setJobTitleState("error");
    } else {
      updateJobTitle();
    }
  };

  const updateJobTitle = async () => {
    setScreenLoader(true);
    const payload = {
      JobTitle: jobProfileName,
      DocumentIdentifier: allResumeData[editIndex].documentIdentifier,
    };

    await JobActions.UpdateJobTile(payload).then(
      (resp) => {
        if (resp.status === 200) {
          setJobTitle(jobProfileName);
          setAllResumeData((prevItems) =>
            prevItems.map((item, index) =>
              index === editIndex ? { ...item, jobTitle: jobProfileName } : item
            )
          );
          setEditTitleModal(false);
          setScreenLoader(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const deleteProfile = async () => {
    setScreenLoader(true);
    await JobActions.DeleteResume(allResumeData[deleteIndex].id).then(
      (resp) => {
        if (resp.status === 200) {
          setScreenLoader(false);
          setDeleteProfileModal(false);
          context.setIsParsed(false);
          checkResumeUploadStatus();
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };

  const selectCountryNotificationHandler = (e, country) => {
    e.preventDefault();
    setSelectedCountryNotification(country);
    countryMasterListNotification.map((countryMaster) => {
      if (countryMaster.name === country) {
        setSelectedCountryCodeNotification(countryMaster.value);
        setCountryCode(countryMaster.value);
      }
    });
    setSelectedLocationNotification("");
    setIsCountryNotification(false);
  };

  const onChangeCountryNotification = (input) => {
    setSelectedCountryNotification(input);
    setIsCountryNotification(true);
    if (input == "") {
      setSelectedCountryCodeNotification("");
    }
    let updatedList = countryListsNotification;
    updatedList = updatedList.filter((filterList) => {
      return (
        !input || filterList.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    });
    if (updatedList.length) {
      setFilteredCountryList(updatedList);
    } else {
      updatedList = ["No matching results found"];
      setFilteredCountryList(updatedList);
    }
  };

  const selectLocationNotificationHandler = (item) => {
    setSelectedLocationNotification(item);
    setIsLocationNotification(false);
  };

  const onChangeLocationNotification = (input) => {
    const payload = {
      field_to_query: "city",
      input_text: selectedCountryCodeNotification + " " + input,
      country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
        "programCountryCode"
      ],
      language: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
        "programLanguageCode"
      ],
    };
    setSelectedLocationNotification(input);
    if (input == "") {
      setIsLocationNotification(false);
    }
    JobActions.LocationAutoComplete(payload).then((res) => {
      res?.data?.auto_suggest?.map((city, id) => {
        const list = res?.data?.auto_suggest?.map((city) =>
          city?.item.slice(3)
        );
        setLocationListsNotification(list);
      });
      setIsLocationNotification(true);
    });
  };

  const saveNotification = () => {
    setScreenLoader(true);
    /*Country */
    let updatedCountry = parsedJSON[0].contact_details;
    Object.keys(updatedCountry).forEach((key) => {
      updatedCountry["country_description"] = selectedCountryNotification;
      updatedCountry["country_code"] = selectedCountryCodeNotification;
      if (key === "country_description") {
        updatedCountry[key] = selectedCountryNotification;
        return updatedCountry;
      }
    });

    /* Location */
    let updatedLocation = parsedJSON[0].contact_details;
    Object.keys(updatedLocation).forEach((key) => {
      if (key === "city") {
        updatedLocation[key] = selectedLocationNotification;
        return updatedLocation;
      }
      if (key === "country_code") {
        // updatedLocation[key] = countryCode;
        updatedLocation[key] = selectedCountryCodeNotification;
      }
    });

    let objStr = JSON.stringify(parsedJSON);
    let jsonBase64 = btoa(objStr);
    let payload = {
      cvIndexModel: {
        country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
          "programCountryCode"
        ],
        parseFileJSONBase64: jsonBase64,
        documentIdentifier: context.documentIdentifier,
        //JobTitle: JobTitle,
        language: JSON.parse(
          window.sessionStorage.getItem("setProgramDetails")
        )["programLanguageCode"],
      },
      notificationAlertModel: {
        hasOpted: hasOpted,
        isDaily: isDaily,
      },
    };
    JobActions.SaveParsedResume(payload).then(
      (res) => {
        if (res.status == "200") {
          checkResumeUploadStatus();
          setScreenLoader(false);
          setNotificationModal(false);
        } else {
          setScreenLoader(false);
        }
      },
      (err) => {
        console.log(err);
        setScreenLoader(false);
      }
    );
  };
  /* Job Match Notification related functions Ends */

  /* this function checks whether cv index is done or not */
  const isResumeSubmitted = (status) => {
    context.setIsResumeUploaded(status);
    checkResumeUploadStatus();
  };

  // Start for showing the JD
  const [isJobDetailsHide, setIsJobDetailsHide] = useState(false);
  const [selectJobForDetails, setSelectJobForDetails] = useState([]);
  const JDclickedId = (id) => {
    if (id === "") {
      return;
    }
    let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
    let selectForJD = refShownJobs.filter((item) => {
      return item.id === id;
    });
    setSelectJobForDetails(selectForJD);
    window.scrollTo(0, 0);
    setIsJobDetailsHide(true);
    JDshowHide(true);
  };
  const jobSaveFromJDpageFlag = (id) => {
    if (id === "") {
      return;
    }
    let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
    const savedJobsId = savedjobs && savedjobs.map((job) => job.id);
    refShownJobs.map((x) => {
      if (x.id === id) {
        if (savedJobsId.includes(id)) {
          x.isSaved = false;
          x.staticCopy.Save = fetchJobCardCMSData("JobSearch.JobCard.Save");
          var filtered = savedjobs.filter(function (job) {
            return job.id != x.id;
          });
          setSavedjobs(filtered);
        } else {
          x.isSaved = true;
          x.staticCopy.Save = fetchJobCardCMSData(
            "JobSearch.JobCard.SavedButton"
          );
          savedjobs.push(x);
          setSavedjobs(savedjobs);
        }
      }
      return x;
    });
    setShownJobs(refShownJobs);
  };
  const appliedJobFromJDpageFlag = (id) => {
    if (id === "") {
      return;
    }
    let refShownJobs = JSON.parse(JSON.stringify(shownJobs));
    refShownJobs?.map((ele1) => {
      if (ele1.id === id) {
        ele1.isApplied = true;
      }
      return ele1;
    });
    setShownJobs(refShownJobs);
  };
  const jobDetailsBackFlag = () => {
    setIsJobDetailsHide(false);
    JDshowHide(false);
  };
  // End for showing the JD

  const firstName = JSON.parse(
    sessionStorage.getItem("setIdTokenClaims")
  )?.firstName;
  let greetingText = fetchJobGreetingsLabel(
    "Job.JobMatches.JobGreetings.NameWithMatches"
  );
  if (greetingText) {
    greetingText = greetingText.replace(
      "#NAME",
      preferredName ? preferredName : firstName
    );
  }
  let greetingTextZero = fetchJobGreetingsLabel(
    "Job.JobMatches.JobGreetings.NameWithNoMatches"
  );
  if (greetingTextZero) {
    greetingTextZero = greetingTextZero.replace(
      "#NAME",
      preferredName ? preferredName : firstName
    );
  }
  let matchText = fetchJobGreetingsLabel(
    "Job.JobMatches.JobGreetings.NumberOfMatches"
  );
  let matchCount;
  matchCount = paginationState.totalCounts;
  if (matchText) {
    matchText = matchText.replace("#NUMBER", matchCount);
  }

  // function to get first letter of every word as uppercase and rest as lowercase
  const getFirstLetterOfEveryWord = (str) => {
    return str?.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const formatText = (text) => {
    const parts = text.split("#");
    return parts.map((part, index) =>
      index % 2 === 1 ? <strong key={index}>{part}</strong> : part
    );
  };

  let profileName = fetchJobGreetingsLabel(
    "Job.JobMatches.JobGreetings.ReplaceMatchProfile"
  );
  if (profileName && allResumeData[selectedTab]) {
    profileName = profileName.replace(
      "MATCH PROFILE NAME",
      getFirstLetterOfEveryWord(allResumeData[selectedTab]?.jobTitle)
    );
    profileName = formatText(profileName);
  }

  const fetchCMSData = (key1, key2) => {
    let data =
      searchCMSData &&
      searchCMSData.pageContent &&
      searchCMSData.pageContent[key1];

    let label = "";
    if (data?.Labels) {
      data?.Labels.map((x) => {
        if (x.Key === key2) {
          label = x.Phrase;
        }
      });
    }
    return label;
  };

  useEffect(() => {
    if (Object.keys(searchCMSData.pageContent).length !== 0) {
      let cType = [];
      let eType = [];
      let sRange = [];
      let sType = [];
      searchCMSData?.pageContent?.["JobSearch.RefineSearch"]?.LOVList?.filter(
        (x) => {
          if (x.Key === "JobSearch.RefineSearch.ContractType") {
            x.Values.map((ct) => {
              let obj = {
                label: ct.Value,
                value: ct.ID,
              };
              cType.push(obj);
            });
            setMasterContractType(cType);
          }
          if (x.Key === "JobSearch.RefineSearch.EmploymentType") {
            x.Values.map((ct) => {
              let obj = {
                label: ct.Value,
                value: ct.ID,
              };
              eType.push(obj);
            });
            setMasterEmploymentType(eType);
          }
          if (x.Key === "JobSearch.RefineSearch.SalaryRange") {
            x.Values.map((ct) => {
              if (ct.Value.includes("-")) {
                let splt = ct.Value.replace(/ /g, "").split("-");
                let spltStart =
                  searchCMSData?.pageContent?.SupportedCurrencies[0]?.Value +
                  splt[0];
                let spltEnd =
                  searchCMSData?.pageContent?.SupportedCurrencies[0]?.Value +
                  splt[1];
                let obj = {
                  label: spltStart + " - " + spltEnd,
                  value: ct.ID,
                };
                sRange.push(obj);
              } else {
                let above = "";
                if (ct.Value.includes("Above")) {
                  let splt = ct.Value.split(" ");
                  above =
                    splt[0] +
                    " " +
                    searchCMSData?.pageContent?.SupportedCurrencies[0]?.Value +
                    splt[1];
                } else {
                  let splt = ct.Value.split(" ");
                  above =
                    splt[0] +
                    " " +
                    searchCMSData?.pageContent?.SupportedCurrencies[0]?.Value +
                    splt[1];
                }
                let obj = {
                  label: above,
                  value: ct.ID,
                };
                sRange.push(obj);
              }
            });
            setMasterSalaryRange(sRange);
          }
          if (x.Key === "JobSearch.RefineSearch.SortBy") {
            //console.log(x.Values);
            x.Values.map((ct) => {
              //console.log(ct);
              let obj = {
                name: ct.Value,
                value: ct.Key,
              };
              sType.push(obj);
            });
            setSortByValues(sType);
            //console.log(sType);
            setSelectedOption(sType[0]);
          }
        }
      );
    }
  }, [searchCMSData]);

  // Start Filter result
  const filterFormHandler = (event, flag) => {
    let salArray = [];
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
    if (flag === refFilterResultForm.company.name) {
      if (event.target.value !== "") {
        refFilterResultForm.company.value = event.target.value;
        refFilterResultForm.company.concatValue =
          " " + "org_normalized_name:" + event.target.value;
        setFilterResultForm(refFilterResultForm);
      } else {
        refFilterResultForm.company.value = "";
        refFilterResultForm.company.concatValue = "";
        setFilterResultForm(refFilterResultForm);
      }
    }
    if (flag === refFilterResultForm.salary.name) {
      if (event.length > 0) {
        refFilterResultForm.salary.value = event;
        let stringValue = "";
        event.map((x) => {
          if (x.value.includes("-")) {
            let splt = x.value.replace(/ /g, "").split("-");
            salArray = [...salArray, ...splt];
            let sortedInc = salArray.sort(function (a, b) {
              return a - b;
            });
            let intialValMin = event?.length == 1 ? 0 : sortedInc[0];
            let secondValMin = event?.length == 1 ? sortedInc[0] : sortedInc[1];
            stringValue =
              " " +
              "salary_minimum:" +
              intialValMin +
              ".." +
              secondValMin +
              " " +
              "salary_maximum:" +
              secondValMin +
              ".." +
              sortedInc[sortedInc?.length - 1];
          } else {
            if (x.value.includes("Above")) {
              let rep = x.value.replace("Above", "");
              stringValue = " " + "salary_minimum:>=" + rep;
            } else {
              let rep = x.value.replace("Below", "");
              stringValue = " " + "salary_maximum:" + "0..." + rep;
            }
          }
        });
        refFilterResultForm.salary.concatValue = stringValue;
        setFilterResultForm(refFilterResultForm);
      } else {
        refFilterResultForm.salary.value = [];
        refFilterResultForm.salary.concatValue = "";
        setFilterResultForm(refFilterResultForm);
      }
    }
    if (flag === refFilterResultForm.contractType.name) {
      if (event.length > 0) {
        refFilterResultForm.contractType.value = event;
        let stringValue = "";
        event.map((x) => {
          stringValue = stringValue + " " + `\"${x.value}\"`;
        });
        refFilterResultForm.contractType.concatValue =
          " contract_type_code:" + "(" + stringValue + ")";
        setFilterResultForm(refFilterResultForm);
      } else {
        refFilterResultForm.contractType.value = [];
        refFilterResultForm.contractType.concatValue = "";
        setFilterResultForm(refFilterResultForm);
      }
    }
    if (flag === refFilterResultForm.employmentType.name) {
      if (event.length > 0) {
        refFilterResultForm.employmentType.value = event;
        let stringValue = "";
        event.map((x) => {
          stringValue = stringValue + " " + x.value;
        });
        refFilterResultForm.employmentType.concatValue =
          " employment_type_code:" + "(" + stringValue + ")";
        setFilterResultForm(refFilterResultForm);
      } else {
        refFilterResultForm.employmentType.value = [];
        refFilterResultForm.employmentType.concatValue = "";
        setFilterResultForm(refFilterResultForm);
      }
    }

    if (
      refFilterResultForm.company.concatValue !== "" ||
      refFilterResultForm.salary.concatValue !== "" ||
      refFilterResultForm.contractType.concatValue !== "" ||
      refFilterResultForm.employmentType.concatValue !== ""
    ) {
      refFilterResultForm.applyButtonStatus = false;
      setFilterResultForm(refFilterResultForm);
    } else {
      refFilterResultForm.applyButtonStatus = true;
      setFilterResultForm(refFilterResultForm);
      clearAllFilterHandler();
    }
  };
  const applyFilterHandler = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));
    refFilterResultForm.isFilterApplied = true;
    setFilterResultForm(refFilterResultForm);
    if (isMobile) {
      setIsFilterOpen(!isFilterOpen);
    }

    const documentDate = candidateLastLoginData
      ?.toLocaleString("yyyy-MM-dd")
      .slice(0, 10);

    let companyName = refFilterResultForm.company.concatValue;
    let contractType = refFilterResultForm.contractType.concatValue;
    let employmentType = refFilterResultForm.employmentType.concatValue;
    let salaryRange = refFilterResultForm.salary.concatValue;
    let payload2 = {
      Querystring:
        extractMatchQuery +
        companyName +
        salaryRange +
        contractType +
        employmentType +
        " documentdate:>=" +
        (candidateLastLoginData ? documentDate : "today-42") +
        " " +
        `city:\"${city}\"` +
        " " +
        `jf_source_country:${selectedCountryCodeNotification?.toUpperCase()}`,
      country: JSON.parse(window.sessionStorage.getItem("setProgramDetails"))[
        "programCountryCode"
      ],
      searchafterscore: "",
      searchafter: "",
      searchafterdocid: "",
      searchafter2: "",
      language: "en",
      OwnerId: candidateID,
      isFlagsRequired: true,
      field: sortByValue,
      order: 'DESCENDING'
    };
    getAllMatchedjobs(payload2, false);
  };

  const clearAllFilterHandler = () => {
    let candidateID =
      context?.idTokenClaims?.signupId != undefined
        ? context?.idTokenClaims?.signupId
        : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))[
            "signupId"
          ];
    let refFilterResultForm = JSON.parse(JSON.stringify(filterResultForm));

    refFilterResultForm.company.value = "";
    refFilterResultForm.company.concatValue = "";
    refFilterResultForm.salary.value = [];
    refFilterResultForm.salary.concatValue = "";
    refFilterResultForm.contractType.value = [];
    refFilterResultForm.contractType.concatValue = "";
    refFilterResultForm.employmentType.value = [];
    refFilterResultForm.employmentType.concatValue = "";
    refFilterResultForm.applyButtonStatus = true;
    setFilterResultForm(refFilterResultForm);

    if (refFilterResultForm.isFilterApplied) {
      refFilterResultForm.applyButtonStatus = true;
      refFilterResultForm.isFilterApplied = false;
      setFilterResultForm(refFilterResultForm);

      const documentDate = candidateLastLoginData
        ?.toLocaleString("yyyy-MM-dd")
        .slice(0, 10);

      let payload;
      if (candidateLastLoginData) {
        payload = {
          Querystring:
            extractMatchQuery +
            " " +
            "documentdate:>=" +
            documentDate +
            " " +
            `city:\"${city}\"` +
            " " +
            `jf_source_country:${selectedCountryCodeNotification?.toUpperCase()}`,
          country: JSON.parse(
            window.sessionStorage.getItem("setProgramDetails")
          )["programCountryCode"],
          searchafterscore: "",
          searchafter: "",
          searchafterdocid: "",
          searchafter2: "",
          language: "en",
          OwnerId: candidateID,
          isFlagsRequired: true,
          field: sortByValue,
          order: 'DESCENDING'
        };
      } else {
        payload = {
          Querystring:
            extractMatchQuery +
            " " +
            "documentdate:>=today-42" +
            " " +
            `city:\"${city}\"` +
            " " +
            `jf_source_country:${selectedCountryCodeNotification?.toUpperCase()}`,
          country: JSON.parse(
            window.sessionStorage.getItem("setProgramDetails")
          )["programCountryCode"],
          searchafterscore: "",
          searchafter: "",
          searchafterdocid: "",
          searchafter2: "",
          language: "en",
          OwnerId: candidateID,
          isFlagsRequired: true,
          field: sortByValue,
          order: 'DESCENDING'
        };
      }

      getAllMatchedjobs(payload, false);
    }
  };
  // End Filter result

  /*Start Compare jobs */
  const [isJobCompareModalVisible, setIsJobCompareModalVisible] =
    useState(false);
  const [selectedCompareJobs, setSelectedCompareJobs] = useState([]);
  const [isCompareJobCardChecked, setIsCompareJobCardChecked] = useState(false);
  const [jobCardID, setJobCardID] = useState();
  let arrSelectedCompareJobs = selectedCompareJobs;

  const closeCompareModal = () => {
    setIsJobCompareModalVisible(false);
  };
  const compareJobHandler = (job) => {
    console.log("job:::::", job);
    setJobCardID(job.id);
    setIsCompareJobCardChecked(true);
    let compareDescription = [
      {
        sectionBody: "",
        sectionHeading: "",
      },
    ];
    if (arrSelectedCompareJobs.length) {
      if (arrSelectedCompareJobs.find((x) => x.id === job.id)) {
        arrSelectedCompareJobs = arrSelectedCompareJobs.filter(
          (filt) => filt.id !== job.id
        );
      } else {
        if (arrSelectedCompareJobs.length <= 4) {
          arrSelectedCompareJobs.push(job);

          let updateCompare = arrSelectedCompareJobs.map((selectedJobs) => {
            compareDescription[0].sectionBody = selectedJobs?.description
              ? selectedJobs?.description
              : "";
            compareDescription[0].sectionHeading =
              selectedJobs.staticCopy.JobDescription;

            return {
              ...selectedJobs,
              description: selectedJobs?.description,
              location: selectedJobs.location,
              salary: selectedJobs.salary,
              datePosted: selectedJobs.postedTimeStamp,
              languageSkills: selectedJobs.languageSkills,
              experience: selectedJobs.experience,
              education: selectedJobs.education,
              startDate: selectedJobs.startDate,
              deadline: selectedJobs.applicationDeadline,
            };
          });
          arrSelectedCompareJobs = updateCompare;
        }
      }
    } else {
      arrSelectedCompareJobs.push(job);
    }
    setSelectedCompareJobs(arrSelectedCompareJobs);
  };

  const handleClearCompare = () => {
    setIsCompareJobCardChecked(false);
    setSelectedCompareJobs([]);
  };

  const maxCompareCard = fetchCMSData(
    "JobSearch.CompareJobs",
    "Job.JobSearch.CompareJobs.MaximumJobsToCompare"
  ).replace("$JobCount$", 5);
  const selectedCompareCards = fetchCMSData(
    "JobSearch.CompareJobs",
    "Job.JobSearch.CompareJobs.CountOfJobsSelected"
  )
    .replace("$JobCount$", 5)
    .replace("$SelectedJobs$", arrSelectedCompareJobs.length);

  const searchFilterFormProps = {
    salarySelectProps: {
      id: filterResultForm.salary.name,
      name: filterResultForm.salary.name,
      placeholder: !isMobile ? "Salary" : "",
      label: isMobile ? "Salary" : "",
      options: masterSalaryRange,
      onChangeHandler: (event) =>
        filterFormHandler(event, filterResultForm.salary.name),
      selectedValue: filterResultForm.salary.value,
      disableSearch: true,
      hasSelectAll: false,
    },
    companySearchSelectProps: {
      name: filterResultForm.company.name,
      id: filterResultForm.company.name,
      placeholder: !isMobile ? "Company" : "",
      label: isMobile ? "Company" : "",
      value: filterResultForm.company.value,
      onChange: (event) =>
        filterFormHandler(event, filterResultForm.company.name),
    },
    contractSelectProps: {
      id: filterResultForm.contractType.name,
      name: filterResultForm.contractType.name,
      placeholder: !isMobile ? "Contract Type" : "",
      label: isMobile ? "Contract Type" : "",
      options: masterContractType,
      onChangeHandler: (event) =>
        filterFormHandler(event, filterResultForm.contractType.name),
      selectedValue: filterResultForm.contractType.value,
      disableSearch: true,
      hasSelectAll: false,
    },
    timeSelectProps: {
      id: filterResultForm.employmentType.name,
      name: filterResultForm.employmentType.name,
      placeholder: !isMobile ? "Full Time/Part Time" : "",
      label: isMobile ? "Full Time/Part Time" : "",
      options: masterEmploymentType,
      onChangeHandler: (event) =>
        filterFormHandler(event, filterResultForm.employmentType.name),
      selectedValue: filterResultForm.employmentType.value,
      disableSearch: true,
      hasSelectAll: false,
    },
    applyFiltersButtonProps: {
      children: "Apply",
      size: "Large",
      type: "button",
      onClick: applyFilterHandler,
      disabled: filterResultForm.applyButtonStatus,
    },
    resetFiltersLinkButtonProps: {
      children: "Clear all filters",
      type: "reset",
      onClick: clearAllFilterHandler,
    },
    onSubmit: (event) => {
      alert("form submitted");
    },
  };

  // tushar onchange method
  const sortByFilter = (option) => {
    if (option.value === 'Relevance')
      setSortByValue('_score');
    else if(option.value === 'Newest')
      setSortByValue('documentdate');
  }

  return (
    <div className="matchesTab">
      {context.isResumeUploaded === true ? (
        <div
          style={
            isJobDetailsHide === true
              ? { height: 0, overflow: "hidden" }
              : { height: "100%", overflow: "visible" }
          }
        >
          <Cirrus.OneColumnLayout type="wrapped">
            {allResumeData && allResumeData.length > 0 && (
              <div className="tabs">
                {allResumeData?.map((resume, index) => {
                  return (
                    <div
                      className="tab"
                      style={
                        selectedTab === index
                          ? { border: "2px solid black" }
                          : {}
                      }
                      onClick={() => loadJobMatchByIndex(index)}
                    >
                      <span>
                        {getFirstLetterOfEveryWord(
                          resume?.jobTitle ? resume?.jobTitle : "Untitled"
                        )}
                      </span>
                      <Cirrus.IconButton
                        name="edit"
                        icon="edit"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          editTitleHandler(index);
                        }}
                      />
                      <Cirrus.IconButton
                        name="delete"
                        icon="close"
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteProfileHandler(index);
                        }}
                      />
                    </div>
                  );
                })}
                {allResumeData && allResumeData.length < 5 && (
                  <div className="tab" onClick={() => uploadNewHandler(false)}>
                    <Cirrus.IconButton icon="add" type="icon" />
                    {fetchJobGreetingsLabel(
                      "Job.JobMatches.JobGreetings.AddAnotherMatch"
                    )}
                  </div>
                )}
              </div>
            )}
            <Cirrus.JobMatchesLayout>
              <Cirrus.JobMatchesLayout.GridCell gridArea="greeting">
                <Cirrus.JobGreetingCard
                  bodyText={
                    paginationState.searchCountAndLabel &&
                    paginationState.searchCountAndLabel
                  }
                  longHeading={matchCount > 0 ? greetingText : greetingTextZero}
                  shortHeading="Your Matches"
                />
              </Cirrus.JobMatchesLayout.GridCell>
              <Cirrus.JobMatchesLayout.GridCell gridArea="sidebar">
                <div
                  className="buttonGroup"
                  style={{
                    backgroundColor:
                      context?.theme === "t-light"
                        ? "var(--colour-container-featured-light)"
                        : "--colour-container-featured-dark",
                  }}
                >
                  <Cirrus.Button
                    isSecondary={false}
                    isFullWidth={true}
                    onClick={editJobMatchHandler}
                  >
                    {fetchJobGreetingsLabel(
                      "Job.JobMatches.JobGreetings.EditExistingMatchCriteria"
                    )}
                  </Cirrus.Button>
                  <Cirrus.Button
                    isSecondary={false}
                    isFullWidth={true}
                    onClick={newResumeAlertHandler}
                  >
                    {fetchJobGreetingsLabel(
                      "Job.JobMatches.JobGreetings.UploadNewResume"
                    )}
                  </Cirrus.Button>
                </div>
              </Cirrus.JobMatchesLayout.GridCell>

              <Cirrus.JobSearchLayout.GridCell gridArea="filters">

                <div className="filter-actions jobMatchFilter">
                  <Cirrus.Button
                    size="Large"
                    leadingIcon={isFilterOpen ? "close" : "filter_alt"}
                    isAlt={true}
                    isSecondary={true}
                    onClick={() => {
                      setIsSaveSearchOpen(false);
                      setIsFilterOpen(!isFilterOpen);
                    }}
                    className={
                      isFilterOpen
                        ? "filter-button filter-button_filter is-active"
                        : "filter-button filter-button_filter"
                    }
                  >
                    {fetchCMSData(
                      "JobSearch.RefineSearch",
                      "Job.JobSearch.RefineSearch.Filters"
                    )}
                  </Cirrus.Button>
                  <Cirrus.Button
                    size="Large"
                    leadingIcon={isSaveSearchOpen ? "close" : "save"}
                    isAlt={true}
                    isSecondary={true}
                    onClick={() => {}}
                    className={
                      isSaveSearchOpen
                        ? "filter-button is-active"
                        : "filter-button"
                    }
                    ref={setSaveSearchReferenceElement}
                  >
                    {fetchCMSData(
                      "JobSearch.SaveJobSearch",
                      "Job.JobSearch.SaveJobSearch.SaveThisSearch"
                    )}
                  </Cirrus.Button>
                  <Cirrus.Button
                    size="Large"
                    leadingIcon={isCompareJobsOpen ? "close" : "compare"}
                    isAlt={true}
                    isSecondary={true}
                    onClick={() => {
                      setIsCompareJobsOpen(!isCompareJobsOpen);
                    }}
                    className={
                      isCompareJobsOpen
                        ? "filter-button is-active"
                        : "filter-button"
                    }
                    ref={setCompareJobsButtonRef}
                  >
                    {fetchCMSData(
                      "JobSearch.CompareJobs",
                      "Job.JobSearch.CompareJobs.CompareJobs"
                    )}
                  </Cirrus.Button>

                  <div className="dropdown-button" ref={dropdownRef}>
                    <Cirrus.Button
                      size="Large"
                      leadingIcon={isSortOpen ? "close" : "filter_list"}
                      isAlt={true}
                      isSecondary={true}
                      onClick={toggleDropdown}
                      className={
                        isSortOpen
                          ? "filter-button filter-button_filter is-active"
                          : "filter-button filter-button_filter"
                      }
                    >
                      {selectedOption?.name}
                    </Cirrus.Button>
                    <div className={`drop-down-content ${dropdownOpen ? "open" : ""}`}>
                    {sortByValues.map((option) => (
                      <button
                        key={option.value}
                        className="dropdown-item"
                        onClick={() => handleSelect(option)}
                      >
                        {option.name} {selectedOption.value === option.value && <span>✓</span>}
                      </button>
                    ))}
                    </div>
                  </div>    
              </div>

                {isFilterOpen && !isMobile && (
                  <Cirrus.SearchFiltersPopup
                    className="filterResult job-filterResult"
                    searchFiltersFormProps={searchFilterFormProps}
                  />
                )}

                {isFilterOpen && isMobile && (
                  <Cirrus.Modal
                    size="mobile"
                    className="jobSearch-filter_mobile"
                    closeButtonProps={{
                      type: "button",
                      isSecondary: false,
                      size: "small",
                      children: "X Close Filters",
                      onClick: () => setIsFilterOpen(!isFilterOpen),
                    }}
                  >
                    <Cirrus.SearchFiltersPopup
                      className="filterResult job-filterResult job-filterResult-mobile"
                      searchFiltersFormProps={searchFilterFormProps}
                    />
                  </Cirrus.Modal>
                )}

                {isCompareJobsOpen && (
                  <div className="compare-jobs">
                    <Cirrus.CompareJobsPopup
                      descriptiveCopy={maxCompareCard}
                      selectionCopy={selectedCompareCards}
                      buttonProps={{
                        children: fetchCMSData(
                          "JobSearch.CompareJobs",
                          "Job.JobSearch.CompareJobs.CompareSelectedJobs"
                        ),
                        size: "Large",
                        onClick: () => {
                          setIsJobCompareModalVisible(true);
                        },
                        type: "button",
                        disabled: selectedCompareJobs.length > 1 ? false : true,
                      }}
                      secondaryButtonProps={{
                        children: fetchCMSData(
                          "JobSearch.CompareJobs",
                          "Job.JobSearch.CompareJobs.ClearAll"
                        ),
                        size: Cirrus.ButtonSizes.Small,
                        isSecondary: true,
                        type: "button",
                        onClick: handleClearCompare,
                      }}
                      referenceElement={compareJobsButtonRef}
                    />
                  </div>
                )}
              </Cirrus.JobSearchLayout.GridCell>

              {notificationModal && (
                <Cirrus.Modal
                  closeIconButtonProps={{
                    isSecondary: true,
                    onClick: notificationModalExit,
                    size: "Small",
                    type: "button",
                    icon: "close",
                  }}
                  headerProps={{
                    children: fetchJobMatchModalLabel(
                      "Job.JobMatches.JobMatchSetUpModal.LocationTitle"
                    ),
                    className: "no-margin",
                    size: "h5",
                  }}
                  isScrollable
                  role="dialog"
                  size="default"
                >
                  {screenLoader && (
                    <div>
                      <Spinner spinnerTimer={screenLoader} />
                    </div>
                  )}
                  <div className="country">
                    <Cirrus.Input
                      errorMessage="Please select Country"
                      id="Country"
                      label={fetchJobMatchModalLabel(
                        "Job.JobMatches.JobMatchSetUpModal.Country"
                      )}
                      name="country"
                      value={selectedCountryNotification}
                      onChange={(e) =>
                        onChangeCountryNotification(e.target.value)
                      }
                      onFocus={() => onChangeCountryNotification()}
                      placeholder="Select Country"
                      autoComplete="off"
                      type="text"
                      defaultValue={context?.programDetails?.programCountryCode?.toLowerCase()}
                    />
                    {isCountryNotification && (
                      <ul className="countryDropdown" ref={countryRef}>
                        {filteredCountryList?.map((country, idx) => {
                          return (
                            <li
                              key={idx}
                              onClick={(e) =>
                                selectCountryNotificationHandler(e, country)
                              }
                            >
                              {country}
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  <br />
                  <div className="location">
                    <Cirrus.Input
                      errorMessage="Please select Location"
                      id="Location"
                      label={fetchJobMatchModalLabel(
                        "Job.JobMatches.JobMatchSetUpModal.Location"
                      )}
                      name="location"
                      value={selectedLocationNotification}
                      onChange={(e) =>
                        onChangeLocationNotification(e.target.value)
                      }
                      placeholder="Select Location"
                      autoComplete="off"
                      type="text"
                    />
                    {isLocationNotification && (
                      <ul className="locationDropdown" ref={locationRef}>
                        {locationListsNotification.length > 0 &&
                          locationListsNotification?.map((location, idx) => {
                            return (
                              <li
                                key={idx}
                                onClick={() =>
                                  selectLocationNotificationHandler(location)
                                }
                              >
                                {location}
                              </li>
                            );
                          })}
                      </ul>
                    )}
                  </div>
                  <br />
                  <Cirrus.Label htmlFor="notify">
                    {fetchJobMatchModalLabel(
                      "Job.JobMatches.JobMatchSetUpModal.NotificationTitle"
                    )}
                  </Cirrus.Label>
                  <br />
                  <Cirrus.Checkbox
                    label={fetchJobMatchModalLabel(
                      "Job.JobMatches.JobMatchSetUpModal.NotificationCheckbox"
                    )}
                    name="notification"
                    onChange={notify}
                    defaultChecked={hasOpted}
                    removeBorder="true"
                    id="notify"
                  />
                  <br />
                  {hasOpted && (
                    <>
                      <div className="notificationRadio">
                        <Cirrus.RadioButton
                          labelText={fetchJobMatchModalLabel(
                            "Job.JobMatches.JobMatchSetUpModal.Daily"
                          )}
                          value={fetchJobMatchModalLabel(
                            "Job.JobMatches.JobMatchSetUpModal.Daily"
                          )}
                          name="notification"
                          onChange={checkedDaily}
                          defaultChecked={isDaily}
                        />
                      </div>
                      <div className="notificationRadio">
                        <Cirrus.RadioButton
                          labelText={fetchJobMatchModalLabel(
                            "Job.JobMatches.JobMatchSetUpModal.Weekly"
                          )}
                          value={fetchJobMatchModalLabel(
                            "Job.JobMatches.JobMatchSetUpModal.Weekly"
                          )}
                          name="notification"
                          onChange={checkedWeekly}
                          defaultChecked={isDaily === true ? false : true}
                        />
                      </div>
                    </>
                  )}
                  <br />
                  <Cirrus.Button
                    type="submit"
                    size="Small"
                    isFullWidth="true"
                    onClick={saveNotification}
                  >
                    {fetchJobMatchModalLabel(
                      "Job.JobMatches.JobMatchSetUpModal.Save"
                    )}
                  </Cirrus.Button>
                </Cirrus.Modal>
              )}

              {editTitleModal && (
                <Cirrus.Modal
                  className="editTitleModal"
                  closeIconButtonProps={{
                    isSecondary: true,
                    onClick: editTitleModalExit,
                    size: "Small",
                    type: "button",
                    icon: "close",
                  }}
                  headerProps={{
                    //children: "Rename Match Profile",
                    className: "no-margin",
                    size: "h5",
                  }}
                  isScrollable
                  role="dialog"
                  size="default"
                >
                  {screenLoader && (
                    <div>
                      <Spinner spinnerTimer={screenLoader} />
                    </div>
                  )}
                  <div className="country">
                    <Cirrus.Input
                      errorMessage={jobProfileNameErrorMsg}
                      id="Country"
                      label={fetchJobGreetingsLabel(
                        "Job.JobMatches.JobGreetings.MatchProfileName"
                      )}
                      name="country"
                      onChange={(e) => onChangeJobTitle(e.target.value)}
                      onFocus={() => {}}
                      type="text"
                      state={jobTitleState}
                      defaultValue={jobTitle}
                      ref={jobTitleRef}
                    />
                  </div>
                  <br />
                  <Cirrus.Button
                    type="submit"
                    size="Small"
                    isFullWidth="true"
                    onClick={updateJobTitle_ButtonClick}
                  >
                    {fetchJobMatchModalLabel(
                      "Job.JobMatches.JobMatchSetUpModal.Save"
                    )}
                  </Cirrus.Button>
                  <br />
                  <br />
                  <Cirrus.Button
                    type="submit"
                    size="Small"
                    isFullWidth="true"
                    onClick={editTitleModalExit}
                  >
                    {"Cancel"}
                  </Cirrus.Button>
                </Cirrus.Modal>
              )}

              {deleteProfileModal && (
                <Cirrus.UpdateNotificationsModal
                  isFullWidth="true"
                  //heading='Alert'
                  bodyText={fetchJobGreetingsLabel(
                    "Job.JobMatches.JobGreetings.DeleteMatchProfile"
                  )}
                  primaryButtonProps={{
                    children: fetchCommonLabel(
                      "CommonComponent.CommonDeleteButton"
                    ),
                    type: "button",
                    isFullWidth: true,
                    onClick: deleteProfile,
                  }}
                  secondaryButtonProps={{
                    children: "Cancel",
                    onClick: deleteProfileModalExit,
                    target: "_blank",
                    isSecondary: false,
                    isFullWidth: true,
                  }}
                  closeButtonProps={{
                    icon: "close",
                    text: "Close",
                    type: "button",
                    isSecondary: true,
                    onClick: deleteProfileModalExit,
                  }}
                >
                  {screenLoader && (
                    <div>
                      <Spinner spinnerTimer={screenLoader} />
                    </div>
                  )}
                </Cirrus.UpdateNotificationsModal>
              )}

              <Cirrus.JobSearchLayout.GridCell gridArea="results">
                {jobLoader === true ? (
                  <div className="spinner-center">
                    <Spinner screenLoader={false} spinnerTimer={jobLoader} />
                    <br />
                    <Cirrus.Label>Loading Matched Jobs...</Cirrus.Label>
                  </div>
                ) : (
                  <>
                    {shownJobs.length > 0 ? (
                      <JobCard
                        JDclickedId={JDclickedId}
                        saveJobHandler={saveJobHandler}
                        isCompareJobsOpen={isCompareJobsOpen}
                        compareJobHandler={compareJobHandler}
                        jobCardID={jobCardID}
                        isCompareJobCardChecked={isCompareJobCardChecked}
                        shownJobs={shownJobs}
                        paginationState={paginationState}
                        loadMoreJobHandler={loadMoreJobHandler}
                        selectedCompareJobs={selectedCompareJobs}
                        layout={"twocolumn"}
                        jobCardCMSData={jobCardCMSData}
                      />
                    ) : (
                      <>
                        <br />
                        <Cirrus.Label>NO RESULT FOUND</Cirrus.Label>
                      </>
                    )}
                  </>
                )}
              </Cirrus.JobSearchLayout.GridCell>
            </Cirrus.JobMatchesLayout>
          </Cirrus.OneColumnLayout>
        </div>
      ) : (
        <Cirrus.OneColumnLayout
          type="wrapped"
          className={context?.hideResumeUpload ? "upload_resume" : ""}
        >
          {context.isGetStartedClicked === false && (
            <Cirrus.JobMatchesLayout>
              <Cirrus.JobMatchesLayout.GridCell gridArea="featured">
                <Cirrus.FeaturedCard
                  heading={fetchGetStartedResumeLabels(
                    "Job.JobMatches.NoResumeUploaded.Title"
                  )}
                  bodyText={fetchGetStartedResumeLabels(
                    "Job.JobMatches.NoResumeUploaded.Description"
                  )}
                  imageProps={{
                    src: searchCMSData?.pageContent["JobSearch.SearchJobBox"]
                      ?.MediaData?.LargeImage?.ImageUrl,
                    altText:
                      searchCMSData?.pageContent["JobSearch.SearchJobBox"]
                        ?.MediaData?.LargeImage?.AltText,
                  }}
                  primaryButtonProps={{
                    children: fetchGetStartedResumeLabels(
                      "Job.JobMatches.NoResumeUploaded.GetStarted"
                    ),
                    size: Cirrus.ButtonSizes.Large,
                    onClick: () => {
                      context.setIsGetStartedClicked(true);
                    },
                  }}
                />
              </Cirrus.JobMatchesLayout.GridCell>
            </Cirrus.JobMatchesLayout>
          )}
          {context.isGetStartedClicked && (
            <Matchsetup
              isResumeUploaded={isResumeSubmitted}
              matchCMSData={matchCMSData}
              parsedString={parsedString}
              notificationPref={notificationPref}
              firstResume={firstResume}
              hasOptedPrev={hasOptedPrev}
              isDailyPrev={isDailyPrev}
            />
          )}
        </Cirrus.OneColumnLayout>
      )}

      <div
        style={
          isJobDetailsHide === true ? { display: "block" } : { display: "none" }
        }
      >
        <JobDetails
          selectJobForDetails={selectJobForDetails}
          jobDetailsBackFlag={jobDetailsBackFlag}
          jobSaveFromJDpageFlag={jobSaveFromJDpageFlag}
          appliedJobFromJDpageFlag={appliedJobFromJDpageFlag}
        />
      </div>
      {/* {isJobCompareModalVisible &&
                <Cirrus.CompareJobsModal
                    closeIconButtonProps={{
                        icon: 'close',
                        isSecondary: true,
                        onClick: closeCompareModal,
                        text: 'Stop comparing jobs',
                        type: 'button'
                    }}
                    heading={fetchCMSData("JobSearch.CompareJobs", "Job.JobSearch.CompareJobs.Compare")}
                    jobs={selectedCompareJobs}
                    staticCopy={{
                        Company: 'Company',
                        ComputerSkills: 'Computer skills',
                        Deadline: 'Deadline to apply',
                        Education: 'Education level',
                        Experience: 'Years of experience',
                        LanguageSkills: 'Language skills',
                        Location: 'Location',
                        Salary: 'Salary',
                        StartDate: 'Start date'
                    }}
                />
            } */}
      {isJobCompareModalVisible && (
        <JobCompareModal
          heading={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.Compare"
          )}
          Deadline={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.DeadLineToApply"
          )}
          // StartDate={fetchCMSData(
          //   "JobSearch.CompareJobs",
          //   "Job.JobSearch.CompareJobs.StartDate"
          // )}
          DatePosted={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.DatePosted"
          )}
          Location={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.Location"
          )}
          Company={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.Company"
          )}
          JobTitle={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.JobTitle"
          )}
          Salary={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.Salary"
          )}
          EmploymentType={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.EmploymentType"
          )}
          ContractType={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.ContractType"
          )}
          JobDescription={fetchCMSData(
            "JobSearch.CompareJobs",
            "Job.JobSearch.CompareJobs.JobDescription"
          )}
          jobs={selectedCompareJobs}
          closeModal={closeCompareModal}
        />
      )}
      {newResumeAlert && (
        <Cirrus.UpdateNotificationsModal
          isFullWidth="true"
          //heading='Alert'
          bodyText={profileName}
          primaryButtonProps={{
            children: "Continue",
            type: "button",
            isFullWidth: true,
            onClick: () => uploadNewHandler(true),
          }}
          secondaryButtonProps={{
            children: "Cancel",
            onClick: function onClick() {
              setNewResumeAlert(false);
            },
            target: "_blank",
            isSecondary: false,
            isFullWidth: true,
          }}
          closeButtonProps={{
            icon: "close",
            text: "Close",
            type: "button",
            isSecondary: true,
            onClick: function onClick() {
              setNewResumeAlert(false);
            },
          }}
        />
      )}
    </div>
  );
}

export default JobMatchesTab;
