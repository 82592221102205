//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import { useEffect, useState, useContext, useRef, useCallback } from 'react';
import { Navigate, React, useNavigate } from "react-router-dom";
import * as Cirrus from 'manpowergroup-cirrus';
import HeaderWithNavigation from '../header/headerWithNavigation';
import womanOnLaptop from 'manpowergroup-cirrus/assets/images/womanOnLaptop.png';
import background from 'manpowergroup-cirrus/assets/images/background.png';
import { JobActions } from "./JobActions";
import { Context } from '../../common/context/Context';
import Spinner from '../../common/components/spinner-loader/Spinner';
import Footer from "../../common/components/footer";
import MultipleFilesUpload from '../../common/components/uploadFile/MultipleFilesUpload';
import ReactTags from 'react-tag-autocomplete';
import { useAppInsightsContext, useTrackEvent } from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { MyProfileActions } from '../myProfile/MyProfileActions';

const Matchsetup = ({ isResumeUploaded, matchCMSData, parsedString, notificationPref, firstResume, hasOptedPrev, isDailyPrev }) => {
    const context = useContext(Context);
    const navigate = useNavigate();
    const [screenLoader, setScreenLoader] = useState(false);
    const [uploadModalError, setUploadModalError] = useState(false);
    let errorLabel;
    const [isEditable, setIsEditable] = useState(false);
    const [uploadModalFlag, setUploadModalFlag] = useState({
        apiFlag: "ResumeParsing",
        replaceID: "",
        uploadType: "Single"
    });
    const [cmsJobMatchSetup, setCmsJobMatchSetup] = useState([]);
    const [contactPreferenceForJobs, setContactPreferenceForJobs] = useState();
    const [parsedData, setParsedData] = useState();
    const [allParsedData, setAllParsedData] = useState([]);
    const [hasOpted, setHasOpted] = useState(false);
    const [isDaily, setIsDaily] = useState(false);

    const [countryMasterList, setCountryMasterList] = useState([]);
    const [isCountry, setIsCountry] = useState(false);
    const [countryLists, setCountryLists] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState();
    const [selectedCountryCode, setSelectedCountryCode] = useState();

    const [isLocation, setIsLocation] = useState(false);
    const [locationLists, setLocationLists] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState();

    const [fileData, setFileData] = useState();
    const [jobTitle, setJobTitle] = useState();
    const [countrySelectionState, setCountrySelectionState] = useState('');

    const [isManagedNotication, setIsManagedNotification] = useState(false)
    const [preferenceButton, setPreferenceButton] = useState(true)
    const [notificationPreference, setNotificationPreference] = useState({
        preferenceTypeEmail: { FieldValue: "EMAIL", status: "", errorMsg: "", FieldEmailCheck: false },
        preferenceTypeSMS: { FieldValue: "SMS", status: "", errorMsg: "", FieldSMSCheck: false },
        preferredEmailAddress: "Primary",
        Loading: true,
        Error: false
    })
    const [emailList, setEmailList] = useState([{ name: "", value: '' }])
    const profile = JSON.parse(window.sessionStorage.getItem("setProfileDetails"))
    const [emailAddress, setEmailAdddress] = useState()
    const [cmsUploadDocuments, setCmsUploadDocuments] = useState({
        title: '',
        description: '',
        pageLabel: [],
        richTexts: [],
    })
    const appInsights = useAppInsightsContext();
    const countryCode = context.programDetails.programCountryCode ? context.programDetails.programCountryCode : JSON.parse(sessionStorage.getItem("setProgramDetails"))['programCountryCode']
    const [matchSetUpLoaded, setMatchSetUpLoaded] = useState(false);

    useEffect(() => {
        setMatchSetUpLoaded(true);
        if(context.isEditExistingClicked){
            context.setIsParsed(false);
            //getResumeData(payload);
            let editExistingData = JSON.parse(atob(parsedString));
                        applyData(editExistingData);
            JobActions.GetCountryList().then((res) => {
                setCountryMasterList(res?.data);
                res?.data?.map((country, id) => {
                    const list = res?.data?.map(country => country.countryName)
                    setCountryLists(list);
                })
            })
            context.setIsParsed(true);
        }

        return () => {  
            context.setIsEditExistingClicked(false);
            context.setIsParsed(false);
        }
    }, []);

    useEffect(() => {
        if (Object.keys(matchCMSData?.pageContent).length !== 0) {
            let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
            const pageName = matchCMSData?.pageContent?.['JobSearch.UploadResumeProcess'];
            refCmsUploadDocuments.title = pageName?.Title;
            refCmsUploadDocuments.uploadDescription = pageName?.Description;
            refCmsUploadDocuments.labels = matchCMSData?.pageContent?.['Common.UploadProgressView']?.Labels;
            refCmsUploadDocuments.exitLabel = pageName?.Labels;
            refCmsUploadDocuments.dragDropText = pageName?.RichTexts;
            setCmsUploadDocuments(refCmsUploadDocuments);
            setEmailAdddress(profile?.emailAddress)
            setCmsJobMatchSetup(matchCMSData?.pageContent?.["JobSearch.UploadResume"]);
            setContactPreferenceForJobs(matchCMSData?.pageContent?.["JobSearch.ContactPreferenceForJobs"]);
        }
    }, [matchCMSData])

    const fetchContactPreferenceForJobsLabels = (key) => {
        let refContactPreferenceForJobs = JSON.parse(JSON.stringify(contactPreferenceForJobs));
        let label = '';
        if (refContactPreferenceForJobs?.Labels) {
            refContactPreferenceForJobs?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    useEffect(() => {
        if (emailAddress !== undefined) {
            setEmailList([{ name: emailAddress, value: emailAddress }])
        }
    }, [emailAddress])

    const handleManageNotication = (e) => {
        const { name, checked, value } = e.target;

        switch (name) {
            case 'smsNotification':
                let objTempSMS = { FieldValue: value, status: '', errorMsg: '', FieldSMSCheck: e.target.checked }
                setNotificationPreference({
                    ...notificationPreference,
                    preferenceTypeSMS: objTempSMS,
                    preferredEmailAddress: "Primary",
                    Loading: false,
                    error: false
                })
                setPreferenceButton(false)
                break;
            case 'emailNotification':
                let objTempEmail = { FieldValue: value, status: '', errorMsg: '', FieldEmailCheck: e.target.checked }

                setNotificationPreference({
                    ...notificationPreference,
                    preferenceTypeEmail: objTempEmail,
                    preferredEmailAddress: "Primary",
                    Loading: false,
                    error: false
                })
                setPreferenceButton(false)
                break;
            case 'preferredEmail':
                setNotificationPreference({
                    ...notificationPreference,
                    preferredEmailAddress: value,
                    Loading: false,
                    error: false
                })
            default:
                break;
        }
    }

    const onSubmitNotificationPreference = async (e) => {
        e.preventDefault()
        setScreenLoader(true)
        setPreferenceButton(true)
        let sCandidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))["candidateID"];

        let prefData = []

        prefData.push({
            "CandidateId": sCandidateID,
            "PreferenceType": "EMAIL",
            "PreferenceValue": fetchContactPreferenceForJobsLabels("Job.JobMatches.ContactPreferenceForJobs.EmailNotifications"),
            "HasOptedforNotification": notificationPreference.preferenceTypeEmail.FieldEmailCheck,
            "PreferredEmailAddress": notificationPreference.preferredEmailAddress
        })

        prefData.push({
            "CandidateId": sCandidateID,
            "PreferenceType": "SMS",
            "PreferenceValue": fetchContactPreferenceForJobsLabels("Job.JobMatches.ContactPreferenceForJobs.SMSNotifications"),
            "HasOptedforNotification": notificationPreference.preferenceTypeSMS.FieldSMSCheck,
            "PreferredEmailAddress": ""
        })

        let res = await JobActions.SavecandidateNotificationPreference(sCandidateID, prefData)

        if (res.status === 200) {
            setPreferenceButton(true)
        }
        setScreenLoader(false)
    }

    const fetchLabel = (key) => {
        let refPolicyContent = JSON.parse(JSON.stringify(cmsJobMatchSetup));
        let label = '';
        if (refPolicyContent?.Labels) {
            refPolicyContent?.Labels.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const fetchExitLabel = (key) => {
        let refCmsUploadDocuments = JSON.parse(JSON.stringify(cmsUploadDocuments));
        let label = '';
        if (refCmsUploadDocuments?.exitLabel) {
            refCmsUploadDocuments?.exitLabel.map((x) => {
                if (x.Key === key) {
                    label = x.Phrase
                }
            })
        }
        return label;
    }

    const uploadModalClick = () => {
        context?.setUploadModal(true);
        context?.setHideResumeUpload(true)
    }

    const uploadModalCloseHandler = () => {
        if(context.uploadModal)
        {
            context?.setHideResumeUpload(false);
        }    
        if(context.newUploadModal)
        {
            context.setHideResumeUpload(true);
            context.setIsResumeUploaded(true);
        }
        context?.setUploadModal(false);
        context?.setNewUploadModal(false);
    }

    const closeModalError = () => {
        setUploadModalError(false);
    }

    let { file } = useState({
        file: null,
        base64URL: ""
    });
    let base64Data;
    let regex = /(\/\d+\*)/g;
    const finishUploadHandler = async (files) => {
        setScreenLoader(true);
        setFileData(files);

        /* Get base64 data */
        file = files[0];
        const getBase64 = (file) => {
            return new Promise((resolve) => {
                let fileInfo;
                let baseURL = "";
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    baseURL = reader.result;
                    resolve(baseURL);
                };
            })
        }
        getBase64(file).then((result) => {
            file["base64"] = result;
            base64Data = result.replace('data:', '').replace(/^.+,/, '');
            
            window.sessionStorage.setItem("base64Data",base64Data);
            let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
            let payload = {
                "candidate_id": candidateID,
                "file_content": base64Data, // Base 64 encoded CV of the candidate
                "country": countryCode,
                "language": JSON.parse(window.sessionStorage.getItem("Language"))["selectedLanguageISOCode"],
            }
            getResumeData(payload);
        });

        JobActions.GetCountryList().then((res) => {
            setCountryMasterList(res?.data);
            res?.data?.map((country, id) => {
                const list = res?.data?.map(country => country.countryName)
                setCountryLists(list);
            })
        })

        //console.log('firstResume in finishUploadHandler : ' +firstResume);
        //when 1st resume is uploaded, we get notificationPreference by calling notificationPreference API
        if(firstResume) {
            let notfnPref = false;
            let objContactPerference = fngetContactPreference();
            objContactPerference.then((resContPref) => {
                if (resContPref.length > 0) {
                let objEmailResult = resContPref.filter(
                    (eleConf) =>
                    String(eleConf.preferenceType).toString().toUpperCase() === "EMAIL"
                );
                if (objEmailResult.length > 0) {
                    //console.log('Email updates checked : '+objEmailResult[0].hasOptedforNotification);
                    if(objEmailResult[0].hasOptedforNotification ) {
                        notfnPref = true;
                        //console.log('setting notfnPref as true');
                    }
                    else {
                        let objSMSResult = resContPref.filter(
                            (eleConf) =>
                            String(eleConf.preferenceType).toString().toUpperCase() === "SMS"
                        );
                
                        if (objSMSResult.length > 0) {
                            //console.log(objSMSResult[0].hasOptedforNotification);
                            if(objSMSResult[0].hasOptedforNotification) 
                                notfnPref = true;
                        }
                    }
                }
                }
                //console.log('notfnPref before setting : '+notfnPref);
                setNotificationCheckbox (notfnPref);
            });
        }
    }

    const setNotificationCheckbox = (notfnPref) => 
    {
        console.log('setNotificationCheckbox : '+notfnPref);
        if (notfnPref) {
            setCheckBoxLabel (fetchLabel("Job.JobMatches.UploadResume.NotificationCheckbox"));
            setCheckBoxDisabled (false);
        }
        else {
            setCheckBoxLabel (fetchLabel("Job.JobMatches.UploadResume.NotificationEnableMyProfileText"));
            setCheckBoxDisabled (true);
        }
    }

    const fngetContactPreference = async () => {
        try {
          let sCandidateID =
            context?.idTokenClaims?.signupId != undefined
              ? context?.idTokenClaims?.signupId
              : JSON.parse(window.sessionStorage.getItem("LoginCandidateToken"))[
                  "candidateID"
                ];
          // let sCandidateID = "A016D264-318B-EC11-A507-501AC5E8D21B"
          let contactPreferencejson = {};
          if (sCandidateID != "") {
            const res = await MyProfileActions.GetcandidateContactPreference(
              sCandidateID
            );
            if (res.status === 200) {
              contactPreferencejson = res.data;
            }
          }
          return contactPreferencejson;
        } catch (error) {
          return {};
        }
      };

    const applyData = (jobData) => {
        let parsedValues = {
            "JobTitle": "",
            "WorkHistory": [],
            "Education": [],
            "Skills": [],
            "ProfessionalSkills": [],
            "SoftSkills": [],
            "Location": "",
            "Country": "",
            "Language": "",
            "CountryCode": ""
        };
        if (Array.isArray(jobData)) {
            jobData?.map((data) => {
                if (data.profile_summary.current_job) { parsedValues.JobTitle = data?.profile_summary?.current_job }
                if (data.employment_history && data.employment_history.length) {
                    parsedValues.WorkHistory.push(data?.employment_history?.map(work => {
                        if (work && work.job_title) {
                            return work.job_title
                        }
                    }))
                }
                if (data.education_history && data.education_history.degrees && data.education_history.degrees.length) {
                    parsedValues.Education.push(data?.education_history?.degrees?.map((degree) => {
                        if (degree && degree.degree_name) {
                            return degree.degree_name
                        }
                    }))
                }
                if (data.education_history && data.education_history?.courses && data.education_history?.courses.length) {
                    parsedValues.Education.push(data?.education_history?.courses?.map((course) => {
                        if (course && course.institute_name) {
                            return course.institute_name
                        }
                    }))
                }
                if (data.it_skills && data.it_skills.length) {
                    parsedValues.Skills.push(data?.it_skills?.map(skill => {
                        if (skill && skill.it_skill) {
                            return skill.it_skill
                        }
                    }))
                }
                if (data.professional_skills && data.professional_skills.length) {
                    parsedValues.ProfessionalSkills.push(data?.professional_skills?.map(skill => {
                        if (skill && skill.professional_skill) {
                            return skill.professional_skill
                        }
                    }))
                }
                if (data.soft_skills && data.soft_skills.length) {
                    parsedValues.SoftSkills.push(data?.soft_skills?.map(skill => {
                        if (skill && skill.soft_skill) {
                            return skill.soft_skill
                        }
                    }))
                }
                if (data.contact_details.city) {
                    parsedValues.Location = data?.contact_details?.city
                }
                if (data.contact_details.country_description) {
                    parsedValues.Country = data?.contact_details?.country_description
                }
                if (data.contact_details.country_code) {
                    parsedValues.CountryCode = data?.contact_details?.country_code
                }
                if (data.language_skills && data.language_skills.length) {
                    parsedValues.Language = data?.language_skills?.map((language) => {
                        if (language && language.language_skill_code) {
                            return language.language_skill_code
                        }
                    })
                }
                data?.employment_history?.map(work => {
                    if (work?.job_description) {
                        let jobDesc = (work?.job_description).replace(/^\s+|\s+$|\s+(?=\s)/g, "");
                        Object.keys(work).forEach(key => {
                            if (key === "job_description") {
                                work[key] = jobDesc
                            }
                        })
                    }
                })

                Object.keys(data?.profile_summary).forEach(key => {
                    if (key === "extracurricular_section") {
                        data.profile_summary[key] = (data.profile_summary.extracurricular_section).replace(/^\s+|\s+$|\s+(?=\s)/g, "")
                    }
                    if (key === "summaryAmbitions_section") {
                        data.profile_summary[key] = (data.profile_summary.summaryAmbitions_section).replace(/^\s+|\s+$|\s+(?=\s)/g, "")
                    }
                })

                setAllParsedData(data);

                setJobTitle(parsedValues.JobTitle);

                /* Work */
                let arrWork = [];
                if (data.employment_history && data.employment_history.length) {
                    data?.employment_history?.map((work, id) => {
                        if (work && work?.job_title) {
                            let obj = { id: id, name: work.job_title }
                            arrWork.push(obj);
                            setParsedWork(arrWork);
                        }
                    });
                }

                /* Education */
                let arrEducation = [];
                if (data.education_history && data.education_history.degrees && data.education_history.degrees.length) {
                    data?.education_history?.degrees.map((degree, id) => {
                        if (degree && degree?.degree_name) {
                            let obj = { id: id, name: degree.degree_name }
                            arrEducation.push(obj);
                        }
                    });
                }
                if (data.education_history && data.education_history.courses && data.education_history.courses.length) {
                    data?.education_history?.courses?.map((course, id) => {
                        if (course && course?.institute_name) {
                            let obj = { id: id, name: course.institute_name }
                            arrEducation.push(obj);
                        }
                    });
                }
                setParsedEducation(arrEducation);

                /* IT Skills */
                let arrSkills = [];
                if (data.it_skills && data.it_skills.length) {
                    data?.it_skills?.map((skill, id) => {
                        if (skill && skill?.it_skill) {
                            let obj = { id: id, name: skill.it_skill }
                            arrSkills.push(obj);
                            setParsedSkills(arrSkills);
                        }
                    });
                }

                /* Professional Skills */
                let arrProSkills = [];
                if (data.professional_skills && data.professional_skills.length) {
                    data?.professional_skills?.map((skill, id) => {
                        if (skill && skill?.professional_skill) {
                            let obj = { id: id, name: skill.professional_skill }
                            arrProSkills.push(obj);
                            setParsedProSkills(arrProSkills);
                        }
                    });
                }

                /* Soft Skills */
                let arrSoftSkills = [];
                if (data.soft_skills && data.soft_skills.length) {
                    data?.soft_skills?.map((skill, id) => {
                        if (skill && skill?.soft_skill) {
                            let obj = { id: id, name: skill.soft_skill }
                            arrSoftSkills.push(obj);
                            setParsedSoftSkills(arrSoftSkills);
                        }
                    });
                }

                setSelectedCountry(parsedValues.Country);
                setSelectedCountryCode(parsedValues.CountryCode)
                setSelectedLocation(parsedValues.Location);

                setParsedData(parsedValues);
                context.setIsParsed(true);
                context.setUploadModal(false);
                context.setNewUploadModal(false);
            })
        }
        else {
            context.setUploadModal(false);
            context.setNewUploadModal(false);
            setUploadModalError(true);
            errorLabel = "Cannot able to parse the resume. Please update your resume and try uploading again.";
        }
    }

    const getResumeData = (payload) => {
        JobActions.GetParsedResume(payload).then(res => {
            if (res.status === 200) {
                setScreenLoader(false);

                applyData(res.data)
                
            } else {
                setScreenLoader(false);
            }
        }, err => {
            setScreenLoader(false);
            console.log(err);
            context.setUploadModal(false);
            context.setNewUploadModal(false);
            setUploadModalError(true);
            errorLabel = "Cannot able to parse the resume. Please update your resume and try uploading again.";
        });
    }

    // const updateResumeData = (id, payload) => {
    //     JobActions.UpdateParseResume(id, payload).then(res => {
    //         if (res.status === 200) {
    //             setScreenLoader(false);

    //             applyData(res.data)
                
    //         } else {
    //             setScreenLoader(false);
    //         }
    //     }, err => {
    //         setScreenLoader(false);
    //         console.log(err);
    //         context.setUploadModal(false);
    //         context.setNewUploadModal(false);
    //         setUploadModalError(true);
    //         errorLabel = "Cannot able to parse the resume. Please update your resume and try uploading again.";
    //     });
    // }

    let formData = new FormData();
    const submitHandler = () => {
        if(!selectedCountry)
        {
            setCountrySelectionState("error");
            return
        }
        else
        {
            setCountrySelectionState("");
        }
        setScreenLoader(true);

        /* Updated Job Title */
        let updatedJobTitle = allParsedData.profile_summary;
        Object.keys(updatedJobTitle).forEach((key) => {
            if (key === "current_job") {
                updatedJobTitle[key] = jobTitle
                return updatedJobTitle
            } else {
                return allParsedData.profile_summary.current_job = jobTitle;
            }
        })

        /*Country */
        let updatedCountry = allParsedData.contact_details;
        Object.keys(updatedCountry).forEach((key) => {
            if (key === "country_description") {
                updatedCountry[key] = selectedCountry
                return updatedCountry
            } else {
                return allParsedData.contact_details.country_description = selectedCountry
            }
        })
        Object.keys(updatedCountry).forEach((key) => {
            if (key === "country_code") {
                updatedCountry[key] = selectedCountryCode
                return updatedCountry
            } else {
                return allParsedData.contact_details.country_code = selectedCountryCode
            }
        })

        /* Location */
        let updatedLocation = allParsedData.contact_details;
        Object.keys(updatedLocation).forEach((key) => {
            if (key === "city") {
                updatedLocation[key] = selectedLocation
                return updatedLocation
            } else {
                return allParsedData.contact_details.city = selectedLocation;
            }
        })

        /* Merge Work to Master Parsed Data */
        let mergeWork
        if (allParsedData.employment_history && allParsedData.employment_history.length) {
            mergeWork = allParsedData.employment_history.map(allWork => {
                if (allWork && allWork.job_title) {
                    let updatedWork = parsedWork.find(work => work.name == allWork.job_title);
                    return updatedWork ? allWork : { ...updatedWork }
                }
            })
            mergeWork = mergeWork.filter(filt => filt !== undefined && Object.keys(filt).length !== 0);
        } else {
            mergeWork = [{ job_title: "", job_description: "", profession_ISCO2008_code: "", profession_ISCO2008_description: "", profession_ONET201_code: "", profession_ONET2010_description: "", profession_class_code: "", profession_class_description: "", profession_code: "", profession_description: "", profession_group_code: "", profession_group_description: "" }];
        }
        let addedWorks = parsedWork.filter(filt => filt && !mergeWork.some(allWork => allWork?.job_title === filt?.name));
        let modifiedWork = addedWorks?.map(addedWork => {
            return { ...addedWork, job_title: addedWork.name, job_description: addedWork.name, profession_ISCO2008_code: "", profession_ISCO2008_description: addedWork.name, profession_ONET201_code: "", profession_ONET2010_description: addedWork.name, profession_class_code: "", profession_class_description: addedWork.name, profession_code: "", profession_description: "", profession_group_code: "", profession_group_description: "" }
        })
        modifiedWork.map(modify => {
            delete modify.id;
            delete modify.name;
        })
        mergeWork = mergeWork.concat(modifiedWork)

        /* Merge Education to Master Parsed Data */
        let mergedegree, mergecourse;
        if (allParsedData.education_history && allParsedData.education_history.degrees && allParsedData.education_history.degrees.length) {
            mergedegree = allParsedData.education_history.degrees.map(allEdu => {
                if (allEdu && allEdu.degree_name) {
                    let updatedEdu = parsedEducation.find(edu => edu.name == allEdu.degree_name);
                    return updatedEdu ? allEdu : { ...updatedEdu }
                }
            })
            mergedegree = mergedegree.filter(filt => filt !== undefined && Object.keys(filt).length !== 0);
        } else {
            mergedegree = [{ degree_name: "", institute_name: "", institute_location: "" }];
        }
        if (allParsedData.education_history && allParsedData.education_history.courses && allParsedData.education_history.courses.length) {
            mergecourse = allParsedData.education_history.courses.map(allEdu => {
                if (allEdu && allEdu.institute_name) {
                    let updatedEdu = parsedEducation.find(edu => edu.name == allEdu.institute_name);
                    return updatedEdu ? allEdu : { ...updatedEdu }
                }
            })
            mergecourse = mergecourse.filter(filt => filt !== undefined && Object.keys(filt).length !== 0);
        } else {
            mergecourse = [{ institute_name: "", institute_location: "" }];
        }
        let mergeEducation = [];
        mergeEducation.push(mergedegree);
        mergeEducation.push(mergecourse);

        let addedEdus = parsedEducation.filter(filt => filt && !mergeEducation.some(allEdu => allEdu?.degree_name === filt?.name));
        let modifiedEdu = addedEdus?.map(addedEdu => {
            return { ...addedEdu, degree_name: addedEdu.name, institute_location: "" }
        })
        modifiedEdu.map(modify => {
            delete modify.id;
            delete modify.name;
        })
        mergeEducation = modifiedEdu;

        /* Merge IT Skills to Master Parsed Data */
        let mergeSkills;
        if (allParsedData.it_skills && allParsedData.it_skills.length) {
            mergeSkills = allParsedData.it_skills.map(allSkills => {
                if (allSkills && allSkills.it_skill) {
                    let updatedSkills = parsedSkills.find(skills => skills.name == allSkills.it_skill);
                    return updatedSkills ? allSkills : { ...updatedSkills }
                }
            })
            mergeSkills = mergeSkills?.filter(filt => filt !== undefined && Object.keys(filt).length !== 0);
        } else {
            mergeSkills = [{ it_skill: "", it_skill_code: "", it_skill_description: "", it_skill_duration: "", it_skill_last_used: "" }];
        }

        let addedSkills = parsedSkills.filter(filt => filt && !mergeSkills.some(allskill => allskill?.it_skill === filt?.name));
        let modifiedSkills = addedSkills?.map(addedskill => {
            return { ...addedskill, it_skill: addedskill.name, it_skill_code: "", it_skill_description: addedskill.name, it_skill_duration: "", it_skill_last_used: "" }
        })
        modifiedSkills.map(modify => {
            delete modify.id;
            delete modify.name;
        })
        mergeSkills = mergeSkills.concat(modifiedSkills)

        /* Merge Professional Skills to Master Parsed Data */
        let mergeProSkills;
        if (allParsedData.professional_skills && allParsedData.professional_skills.length) {
            mergeProSkills = allParsedData.professional_skills.map(allSkills => {
                if (allSkills && allSkills.professional_skill) {
                    let updatedSkills = parsedProSkills.find(skills => skills.name == allSkills.professional_skill);
                    return updatedSkills ? allSkills : { ...updatedSkills }
                }
            })
            mergeProSkills = mergeProSkills.filter(filt => filt !== undefined && Object.keys(filt).length !== 0);
            if(mergeProSkills.length === 0)
            {
                mergeProSkills = [{ professional_skill: "", professional_skill_code: "", professional_skill_description: "", professional_skill_last_used: "", professional_skill_years: "" }];
            }
        } else {
            mergeProSkills = [{ professional_skill: "", professional_skill_code: "", professional_skill_description: "", professional_skill_last_used: "", professional_skill_years: "" }];
        }

        let addedProSkills = parsedProSkills.filter(filt => filt && !mergeProSkills.some(allskill => allskill?.professional_skill === filt?.name));
        let modifiedProSkills = addedProSkills?.map(addedskill => {
            return { ...addedskill, professional_skill: addedskill.name, professional_skill_code: "", professional_skill_description: addedskill.name, professional_skill_last_used: "", professional_skill_years: "" }
        })
        modifiedProSkills.map(modify => {
            delete modify.id;
            delete modify.name;
        })
        mergeProSkills = mergeProSkills.concat(modifiedProSkills)

        /* Merge Soft Skills to Master Parsed Data */
        let mergeSoftSkills;
        if (allParsedData.soft_skills && allParsedData.soft_skills.length) {
            mergeSoftSkills = allParsedData.soft_skills.map(allSkills => {
                if (allSkills && allSkills.soft_skill) {
                    let updatedSkills = parsedSoftSkills.find(skills => skills.name == allSkills.soft_skill);
                    return updatedSkills ? allSkills : { ...updatedSkills }
                }
            })
            mergeSoftSkills = mergeSoftSkills.filter(filt => filt !== undefined && Object.keys(filt).length !== 0);
            if(mergeSoftSkills.length === 0)
            {
                mergeSoftSkills = [{ soft_skill: "", soft_skill_description: "", soft_skill_code: "" }];
            }
        } else {
            mergeSoftSkills = [{ soft_skill: "", soft_skill_description: "", soft_skill_code: "" }];
        }

        let addedSoftSkills = parsedSoftSkills.filter(filt => filt && !mergeSoftSkills.some(allskill => allskill?.soft_skill === filt?.name));
        let modifiedSoftSkills = addedSoftSkills?.map(addedskill => {
            return { ...addedskill, soft_skill: addedskill.name, soft_skill_code: "" }
        })
        modifiedSoftSkills.map(modify => {
            delete modify.id;
            delete modify.name;
        })
        mergeSoftSkills = mergeSoftSkills.concat(modifiedSoftSkills);

        let UpdatedParsedData = {
            ...allParsedData,
            profile_summary: updatedJobTitle,
            employment_history: mergeWork,
            education_history: { degrees: mergeEducation },
            it_skills: mergeSkills,
            professional_skills: mergeProSkills,
            soft_skills: mergeSoftSkills
        }

        Object.keys(UpdatedParsedData.contact_details).forEach(key => {
            if (UpdatedParsedData.contact_details[key] === null || UpdatedParsedData.contact_details[key] === "Null") {
                delete UpdatedParsedData.contact_details[key];
            }
        });


        let objStr = JSON.stringify([UpdatedParsedData]);
        let jsonBase64 = btoa(objStr);
        let payload = {
            "cvIndexModel": {
                "country": countryCode,
                "parseFileJSONBase64": jsonBase64,
                "documentIdentifier": context.documentIdentifier,
                "JobTitle": jobTitle,
                "language": JSON.parse(window.sessionStorage.getItem("Language"))["selectedLanguageISOCode"],
            },
            "notificationAlertModel": {
                "hasOpted": hasOpted,
                "isDaily": isDaily
            }
        }
        if (fileData || context.isEditExistingClicked) {
            if(!context.isEditExistingClicked)
            {
                for (let i = 0; i < fileData.length; i++) {
                    formData.append(fileData[i].name, fileData[i])
                }
            }    
            let candidateID = (context?.idTokenClaims?.signupId != undefined) ? context?.idTokenClaims?.signupId : JSON.parse(window.sessionStorage.getItem("setIdTokenClaims"))["signupId"];
            formData.append('OwnerId', candidateID);
            formData.append('OwnerType', "Candidate");
            if(context.isResumeBuilder)
            {
                //formData.IsResumeBuilder = true;
                formData.append('IsResumeBuilder', true);
            }

            if(!context.isEditExistingClicked) {
                if(context.isResumeBuilder)
                {
                    JobActions.UpdateResume(context.documentId, formData).then(res => {
                        if (res.status === 200) {
                            let resData = res.data;
                            payload.cvIndexModel.documentIdentifier = resData[0].documentIdentifier;
                            saveParsedResume(resData[0].documentIdentifier, payload);
                            if (resData.extractMatchQuery === "") {
                                JobActions.RevertCV(resData.documentIdentifier).then(result => {
                                    if (result.status === 200) {
        
                                    } else {
                                    }
                                }, err => {
                                    console.log(err)
                                })
                            }
                        } else {
                            setScreenLoader(false);
                        }
                    }, err => {
                        console.log(err);
                        setScreenLoader(false);
                    });
                }   
                else
                {
                    JobActions.UploadResume(formData).then(res => {
                        if (res.status === 200) {
                            let resData = res.data;
                            payload.cvIndexModel.documentIdentifier = resData[0].documentIdentifier;
                            saveParsedResume(resData[0].documentIdentifier, payload);
                            if (resData.extractMatchQuery === "") {
                                JobActions.RevertCV(resData.documentIdentifier).then(result => {
                                    if (result.status === 200) {
        
                                    } else {
                                    }
                                }, err => {
                                    console.log(err)
                                })
                            }
                        } else {
                            setScreenLoader(false);
                        }
                    }, err => {
                        console.log(err);
                        setScreenLoader(false);
                    });
                } 
                
            }
            else
            {
                saveParsedResume(context.documentIdentifier, payload);
            }
        }
    }

    const saveParsedResume = (documentIdentifier, payload) => {
        JobActions.SaveParsedResume(payload).then(resp => {
            if (resp.status == '200') {
                TrackActions.EventTrack(appInsights, "UploadResume")
                TrackActions.PageAction("UploadResume")
                setScreenLoader(false)
                isResumeUploaded(true)
            } else {
                setScreenLoader(false);
                setUploadModalError(true);
                JobActions.RevertCV(documentIdentifier).then(result => {
                    if (result.status === 200) {

                    } else {
                    }
                }, err => {
                    console.log(err)
                })
            }
        }, err => {
            console.log(err)
            setScreenLoader(false);
            setUploadModalError(true);
            errorLabel = "Something went wrong. Please update your resume and try uploading again.";
            JobActions.RevertCV(documentIdentifier).then(result => {
                if (result.status === 200) {

                } else {
                }
            }, err => {
                console.log(err)
            })
        })
    }
    const editHandler = () => {
        setIsEditable(true);
    }
    const editJobTitle = (input) => {
        setJobTitle(input);
    }

    /* Work Histroy */
    const [parsedWork, setParsedWork] = useState([]);
    const reactWork = useRef();
    const [workSuggestions, setWorkSuggestions] = useState([]);

    const deleteWork = useCallback((tagIndex) => {
        setParsedWork(parsedWork.filter((_, i) => i !== tagIndex))
    }, [parsedWork])

    const addNewWork = useCallback((newTag) => {
        setParsedWork([...parsedWork, newTag])
    }, [parsedWork])

    /* Education */
    const [parsedEducation, setParsedEducation] = useState([]);
    const reactEducation = useRef();
    const [educationSuggestions, setEducationSuggestions] = useState([]);

    const [checkBoxLabel, setCheckBoxLabel] = useState('');
    const [isCheckBoxDisabled, setCheckBoxDisabled] = useState(false);
    const [isNotnRadioDisabled, setNotnRadioDisabled] = useState(false);

    const deleteEducation = useCallback((tagIndex) => {
        setParsedEducation(parsedEducation.filter((_, i) => i !== tagIndex))
    }, [parsedEducation])

    const addNewEducation = useCallback((newTag) => {
        setParsedEducation([...parsedEducation, newTag])
    }, [parsedEducation])

    /* Skills */
    const [parsedSkills, setParsedSkills] = useState([]);
    const reactSkills = useRef();
    const [skillsSuggestions, setSkillsSuggestions] = useState([]);

    const deleteSkill = useCallback((tagIndex) => {
        setParsedSkills(parsedSkills.filter((_, i) => i !== tagIndex))
    }, [parsedSkills])

    const addNewSkill = useCallback((newTag) => {
        setParsedSkills([...parsedSkills, newTag])
    }, [parsedSkills])

    /* Professional Skills */
    const [parsedProSkills, setParsedProSkills] = useState([]);
    const reactProSkills = useRef();
    const [proSkillsSuggestions, setProSkillsSuggestions] = useState([]);

    const deleteProSkill = useCallback((tagIndex) => {
        setParsedProSkills(parsedProSkills.filter((_, i) => i !== tagIndex))
    }, [parsedProSkills])

    const addNewProSkill = useCallback((newTag) => {
        setParsedProSkills([...parsedProSkills, newTag])
    }, [parsedProSkills])

    /* Soft Skills */
    const [parsedSoftSkills, setParsedSoftSkills] = useState([]);
    const reactSoftSkills = useRef();
    const [softSkillsSuggestions, setSoftSkillsSuggestions] = useState([]);

    const deleteSoftSkill = useCallback((tagIndex) => {
        setParsedSoftSkills(parsedSoftSkills.filter((_, i) => i !== tagIndex))
    }, [parsedSoftSkills])

    const addNewSoftSkill = useCallback((newTag) => {
        setParsedSoftSkills([...parsedSoftSkills, newTag])
    }, [parsedSoftSkills])
  
    useEffect(() => { 
        //when more than 1 resume is uploaded
        //console.log('firstResume useEffect : '+firstResume);
        console.log('notificationPref, firstResume in matchsetup useEffect : '+notificationPref + ' , ' + firstResume);
        if(!firstResume) {
            setNotificationCheckbox (notificationPref);
        }        
    }, [cmsJobMatchSetup]);

    useEffect(() => {
        //console.log('In matchsetup, isEditExistingClicked : '+context.isEditExistingClicked);
        if(context.isEditExistingClicked) {
            console.log('In matchsetup, hasOptedPrev : ' + hasOptedPrev + ' , isDailyPrev : ' +isDailyPrev);
            setHasOpted (hasOptedPrev);
            setIsDaily (isDailyPrev);
            //If Email and sms both notifications are set as OFF in profile Section
            if(notificationPref == false) {
                setNotnRadioDisabled (true);
            }
        }
    }, []);

    const workInputChange = useCallback((input) => {
        if (input.length > 2) {
            const payload = {
                "field_to_query": "workfield.workfield",
                "input_text": input,
                "country": countryCode,
                "language": JSON.parse(window.sessionStorage.getItem("Language"))["selectedLanguageISOCode"],
            }
            JobActions.LocationAutoComplete(payload).then((res) => {
                let arrWork = [];
                res?.data?.auto_suggest?.map((work, id) => {
                    let obj = { id: id, name: work.item }
                    if (work?.item) {
                        arrWork.push(obj);
                        setTimeout(() => {
                            setWorkSuggestions(arrWork);
                        })
                    }
                });
            })
        }
    });

    const educationInputChange = useCallback((input) => {
        if (input.length > 2) {
            const payload = {
                "field_to_query": "educationlevel_international",
                "input_text": input,
                "country": countryCode,
                "language": JSON.parse(window.sessionStorage.getItem("Language"))["selectedLanguageISOCode"],
            }
            JobActions.LocationAutoComplete(payload).then((res) => {
                let arrEducation = [];
                res?.data?.auto_suggest?.map((education, id) => {
                    let obj = { id: id, name: education.item }
                    if (education?.item) {
                        arrEducation.push(obj);
                        setTimeout(() => {
                            setEducationSuggestions(arrEducation);
                        })
                    }
                });
            })
        }
    })

    const skillsInputChange = useCallback((input) => {
        if (input.length > 2) {
            const payload = {
                "field_to_query": "compskills",
                "input_text": input,
                "country": countryCode,
                "language": JSON.parse(window.sessionStorage.getItem("Language"))["selectedLanguageISOCode"],
            }
            JobActions.LocationAutoComplete(payload).then((res) => {
                let arrSkills = [];
                res?.data?.auto_suggest?.map((skill, id) => {
                    let obj = { id: id, name: skill.item }
                    if (skill?.item) {
                        arrSkills.push(obj);
                        setTimeout(() => {
                            setSkillsSuggestions(arrSkills);
                        })
                    }
                });
            })
        }
    })

    const proSkillsInputChange = useCallback((input) => {
        if (input.length > 2) {
            const payload = {
                "field_to_query": "FULLTEXT",
                "input_text": input,
                "country": countryCode,
                "language": JSON.parse(window.sessionStorage.getItem("Language"))["selectedLanguageISOCode"],
            }
            JobActions.LocationAutoComplete(payload).then((res) => {
                let arrProSkills = [];
                res?.data?.auto_suggest?.map((skill, id) => {
                    let obj = { id: id, name: skill.item }
                    if (skill?.item) {
                        arrProSkills.push(obj);
                        setTimeout(() => {
                            setProSkillsSuggestions(arrProSkills);
                        })
                    }
                });
            })
        }
    })

    const softSkillsInputChange = useCallback((input) => {
        if (input.length > 2) {
            const payload = {
                "field_to_query": "FULLTEXT",
                "input_text": input,
                "country": countryCode,
                "language": JSON.parse(window.sessionStorage.getItem("Language"))["selectedLanguageISOCode"],
            }
            JobActions.LocationAutoComplete(payload).then((res) => {
                let arrSoftSkills = [];
                res?.data?.auto_suggest?.map((skill, id) => {
                    let obj = { id: id, name: skill.item }
                    if (skill?.item) {
                        arrSoftSkills.push(obj);
                        setTimeout(() => {
                            setSoftSkillsSuggestions(arrSoftSkills);
                        })
                    }
                });
            })
        }
    })

    const notify = (e) => {
        if (e.target.checked) {
            setHasOpted(true)
            setIsDaily(false)
        } else {
            setHasOpted(false)
            setIsDaily(true)
        }
    }

    const checkedDaily = (e) => {
        if (e.target.checked) {
            setIsDaily(true)
        }
    }

    const checkedWeekly = (e) => {
        if (e.target.checked) {
            setIsDaily(false)
        }
    }

    const countryRef = useRef(null);
    function useOutsideCountryListClick(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutsideCountryList(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsCountry(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutsideCountryList);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutsideCountryList);
            };
        }, [ref]);
    }
    useOutsideCountryListClick(countryRef)

    const locationRef = useRef(null);
    function useOutsideLocationListClick(ref) {
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutsideLocationList(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsLocation(false);
                }
            }
            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutsideLocationList);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutsideLocationList);
            };
        }, [ref]);
    }
    useOutsideLocationListClick(locationRef);

    const selectCountryHandler = (e, country) => {
        e.preventDefault()
        setSelectedCountry(country)
        countryMasterList.map(countryMaster => {
            if (countryMaster.countryName === country) {
                setSelectedCountryCode(countryMaster.countryCode_alpha_2);
            }
        })
        setSelectedLocation("")
        setIsCountry(false)
    }
    const [filteredCountryList, setFilteredCountryList] = useState();
    const onChangeCountry = (input) => {
        setSelectedCountry(input);
        setIsCountry(true);
        if (input == "") {
            setSelectedCountryCode("")
        }
        else
        {
            setCountrySelectionState("");
        }
        let updatedList = countryLists;
        updatedList = updatedList.filter((filterList) => {
            return !input || filterList.toLowerCase().indexOf(input.toLowerCase()) >= 0
        });
        if (updatedList.length) {
            setFilteredCountryList(updatedList);
        } else {
            updatedList = ["No matching results found"];
            setFilteredCountryList(updatedList);
        }
    }

    const selectLocationHandler = (item) => {
        setSelectedLocation(item);
        setIsLocation(false);
    }
    const onChangeLocation = (input) => {
        const payload = {
            "field_to_query": "city",
            "input_text": selectedCountryCode + " " + input,
            "country": countryCode,
            "language": JSON.parse(window.sessionStorage.getItem("Language"))["selectedLanguageISOCode"],
        }
        setSelectedLocation(input);
        JobActions.LocationAutoComplete(payload).then((res) => {
            res?.data?.auto_suggest?.map((city, id) => {
                const list = res?.data?.auto_suggest?.map((city) => city?.item.slice(3))
                setLocationLists(list);
            });
            setIsLocation(true);
        })
    }

    const addWorkHandler = () => {
        let workHistoryTag = document.querySelector('.work-history .react-tags__search');
        workHistoryTag.style.display = 'block';
    }
    const addEduHandler = () => {
        let eduHistoryTag = document.querySelector('.education-history .react-tags__search');
        eduHistoryTag.style.display = 'block';
    }
    const addSkillHandler = () => {
        let skillHistoryTag = document.querySelector('.skill-history .react-tags__search');
        skillHistoryTag.style.display = 'block';
    }
    const addProSkillHandler = () => {
        let proSkillHistoryTag = document.querySelector('.pro-skill-history .react-tags__search');
        proSkillHistoryTag.style.display = 'block';
    }

    const addSoftSkillHandler = () => {
        let softSkillHistoryTag = document.querySelector('.soft-skill-history .react-tags__search');
        softSkillHistoryTag.style.display = 'block';
    }

    if (screenLoader) {
        return (<div>
            <Spinner spinnerTimer={screenLoader} />
        </div>)
    }

    return (
        <>
            <Cirrus.JobMatchesLayout>
                <Cirrus.JobMatchesLayout.GridCell gridArea="featured">
                    {!context?.hideResumeUpload && (<Cirrus.FeaturedCard
                        heading={fetchLabel("Job.JobMatches.UploadResume.UploadResumeTitle")}
                        bodyText={fetchLabel("Job.JobMatches.UploadResume.UploadResumeDescription")}
                        imageProps={{
                            src: womanOnLaptop,
                            altText: 'Woman using her laptop while smiling'
                        }}
                        primaryButtonProps={{
                            children: fetchLabel("Job.JobMatches.UploadResume.UploadResume"),
                            size: Cirrus.ButtonSizes.Large,
                            onClick: uploadModalClick
                        }}
                    />)
                    }
                    {
                        (context.uploadModal || context.newUploadModal) && matchSetUpLoaded && <Cirrus.Modal
                            closeButtonProps={{
                                children: fetchExitLabel("JobMatches.UploadResumeProcess.Exit"),
                                isSecondary: true,
                                onClick: uploadModalCloseHandler,
                                size: 'Small',
                                type: 'button',
                                disabled: context.uploadModalExitDisable
                            }}
                            headerProps={{
                                children: cmsUploadDocuments.title,
                                className: 'no-margin',
                                size: 'h5'
                            }}
                            isScrollable
                            role="dialog"
                            size="default"
                        >
                            <MultipleFilesUpload cmsContent={cmsUploadDocuments} finishUploadHandler={finishUploadHandler} uploadModalFlag={uploadModalFlag}
                                extension={"Common.UploadProgressView"}
                                origin={"Resume"}
                            />
                        </Cirrus.Modal>
                    }
                    {
                        uploadModalError && <Cirrus.Modal>
                            <div style={{ maxWidth: "350px", textAlign: "center" }}>
                                <Cirrus.Label>
                                    {fetchLabel("Job.JobMatches.UploadResume.ErrorValidationForUploadResume")}
                                </Cirrus.Label><br /><br />
                                <Cirrus.Button onClick={closeModalError}>Ok</Cirrus.Button>
                            </div>
                        </Cirrus.Modal>
                    }
                </Cirrus.JobMatchesLayout.GridCell>
            </Cirrus.JobMatchesLayout>

            {context.isParsed &&
                <Cirrus.JobMatchSetupContainer
                    primaryButton={{
                        children: fetchLabel("Job.JobMatches.UploadResume.Submit"),
                        size: 'Small',
                        type: 'submit',
                        onClick: submitHandler,
                        isFullWidth: 'false'
                    }}
                >
                    <div>
                        <Cirrus.PageHeading
                            heading={fetchLabel("Job.JobMatches.UploadResume.ResumeTitle")}
                            subheading={fetchLabel("Job.JobMatches.UploadResume.ResumeDescription")}
                            isSmall='true'
                        />
                    </div>
                    <div>
                        <section>
                            <div className='parsedHeadings'>
                                {fetchLabel("Job.JobMatches.UploadResume.JobTitleHeading")}
                            </div>
                            <div className='parsedContainer'>
                                {!isEditable &&
                                    <div className='parsedTags'>
                                        <span>{parsedData.JobTitle}</span>
                                    </div>}
                                {
                                    isEditable &&
                                    <Cirrus.Input id="jobtitle" label="" name="jobtitle" type='text' value={jobTitle} onChange={(e) => editJobTitle(e.target.value)} className='editableInput' />} {/* onFocus={(e) => {e.target.select()}} */}
                                <Cirrus.IconButton icon='edit' type='button' isSecondary='true' onClick={editHandler} className='editIcon' />
                            </div>
                        </section>
                        <section className='relativePosition'>
                            <div className='parsedHeadings'>
                                {fetchLabel("Job.JobMatches.UploadResume.WorkHistoryHeading")}
                            </div>
                            <ReactTags
                                ref={reactWork}
                                tags={parsedWork}
                                suggestions={workSuggestions}
                                onDelete={deleteWork}
                                onAddition={addNewWork}
                                allowBackspace={false}
                                allowNew={true}
                                autoresize={false}
                                classNames={
                                    { selectedTag: 'parsedTags', root: 'react-tags work-history' }
                                }
                                removeButtonText='Remove'
                                placeholderText='Add Work...'
                                onInput={workInputChange}
                            />
                            <Cirrus.IconButton icon='add' type='button' isSecondary='true' onClick={addWorkHandler} className='addIcon' />
                        </section>
                        <section className='relativePosition'>
                            <div className='parsedHeadings'>
                                {fetchLabel("Job.JobMatches.UploadResume.EducationHeading")}
                            </div>
                            <ReactTags
                                ref={reactEducation}
                                tags={parsedEducation}
                                suggestions={educationSuggestions}
                                onDelete={deleteEducation}
                                onAddition={addNewEducation}
                                allowBackspace={false}
                                allowNew={true}
                                autoresize={false}
                                classNames={
                                    { selectedTag: 'parsedTags', root: 'react-tags education-history' }
                                }
                                removeButtonText='Remove'
                                placeholderText='Add Education...'
                                onInput={educationInputChange}
                            />
                            <Cirrus.IconButton icon='add' type='button' isSecondary='true' onClick={addEduHandler} className='addIcon' />
                        </section>
                        <section className='relativePosition'>
                            <div className='parsedHeadings'>
                                {fetchLabel("Job.JobMatches.UploadResume.ITSkillsHeading")}
                            </div>
                            <ReactTags
                                ref={reactSkills}
                                tags={parsedSkills}
                                suggestions={skillsSuggestions}
                                onDelete={deleteSkill}
                                onAddition={addNewSkill}
                                allowBackspace={false}
                                allowNew={false}
                                autoresize={false}
                                classNames={
                                    { selectedTag: 'parsedTags', root: 'react-tags skill-history' }
                                }
                                removeButtonText='Remove'
                                placeholderText='Add Skills...'
                                onInput={skillsInputChange}
                            />
                            <Cirrus.IconButton icon='add' type='button' isSecondary='true' onClick={addSkillHandler} className='addIcon' />
                        </section>
                        <section className='relativePosition'>
                            <div className='parsedHeadings'>
                                {fetchLabel("Job.JobMatches.UploadResume.ProfessionalSkillsHeading")}
                            </div>
                            <ReactTags
                                ref={reactProSkills}
                                tags={parsedProSkills}
                                suggestions={proSkillsSuggestions}
                                onDelete={deleteProSkill}
                                onAddition={addNewProSkill}
                                allowBackspace={false}
                                allowNew={true}
                                autoresize={false}
                                classNames={
                                    { selectedTag: 'parsedTags', root: 'react-tags pro-skill-history' }
                                }
                                removeButtonText='Remove'
                                placeholderText='Add Skills...'
                                onInput={proSkillsInputChange}
                            />
                            <Cirrus.IconButton icon='add' type='button' isSecondary='true' onClick={addProSkillHandler} className='addIcon' />
                        </section>
                        <section className='relativePosition'>
                            <div className='parsedHeadings'>
                                {fetchLabel("Job.JobMatches.UploadResume.SoftSkillsHeading")}
                            </div>
                            <ReactTags
                                ref={reactSoftSkills}
                                tags={parsedSoftSkills}
                                suggestions={softSkillsSuggestions}
                                onDelete={deleteSoftSkill}
                                onAddition={addNewSoftSkill}
                                allowBackspace={false}
                                allowNew={true}
                                autoresize={false}
                                classNames={
                                    { selectedTag: 'parsedTags', root: 'react-tags soft-skill-history' }
                                }
                                removeButtonText='Remove'
                                placeholderText='Add Skills...'
                                onInput={softSkillsInputChange}
                            />
                            <Cirrus.IconButton icon='add' type='button' isSecondary='true' onClick={addSoftSkillHandler} className='addIcon' />
                        </section>
                    </div>
                    <div>
                        <Cirrus.PageHeading
                            heading={fetchLabel("Job.JobMatches.UploadResume.LocationTitle")}
                            subheading={fetchLabel("Job.JobMatches.UploadResume.LocationDescription")}
                            isSmall='true'
                        />
                    </div>
                    <div>
                        <div className='country'>
                            <Cirrus.Input
                                errorMessage="Please select Country"
                                id="Country"
                                label="Country"
                                name="country"
                                value={selectedCountry}
                                onChange={(e) => onChangeCountry(e.target.value)}
                                onFocus={() => onChangeCountry()}
                                placeholder="Select Country"
                                autoComplete='off'
                                state={countrySelectionState}
                                type='text'
                                ref={countryRef}
                            />
                            {isCountry &&
                                <ul className='countryDropdown' ref={countryRef}>
                                    {filteredCountryList?.map((country, idx) => {
                                        return <li key={idx} onClick={(e) => selectCountryHandler(e, country)}>{country}</li>
                                    })}
                                </ul>
                            }
                        </div>
                        <br />
                        <div className='location'>
                            <Cirrus.Input
                                errorMessage="Please select Location"
                                id="location"
                                label="Location"
                                name="location"
                                value={selectedLocation}
                                onChange={(e) => onChangeLocation(e.target.value)}
                                placeholder="Select Location"
                                autoComplete='off'
                                type='text'
                            />
                            {isLocation &&
                                <ul className='locationDropdown' ref={locationRef}>
                                    {locationLists.length > 0 && locationLists?.map((location, idx) => {
                                        return <li key={idx} onClick={() => selectLocationHandler(location)}>{location}</li>
                                    })}
                                </ul>
                            }
                        </div>
                    </div>
                    <div>
                        {/* <Cirrus.PageHeading
                            heading={fetchLabel("Job.JobMatches.UploadResume.NotificationTitle")}
                            subheading={fetchLabel("Job.JobMatches.UploadResume.NotificationDescription")}
                            isSmall='true'
                        /><br /> 
                            <Cirrus.Button
                            className='mb-5'
                            children={isManagedNotication ? fetchLabel("Job.JobMatches.UploadResume.CloseNotificationPreference") : fetchLabel("Job.JobMatches.UploadResume.ManageNotifications")}
                            isSecondary="true"
                            onClick={() => { setIsManagedNotification(!isManagedNotication) }}
                        /> */}
                        {isManagedNotication && (
                            <div className='mb-5 managed_notification'>
                                <Cirrus.Title size='medium'>{contactPreferenceForJobs.Description}</Cirrus.Title >
                                <Cirrus.Checkbox
                                    label={fetchContactPreferenceForJobsLabels("Job.JobMatches.ContactPreferenceForJobs.SMSNotifications")}
                                    name='smsNotification'
                                    value={notificationPreference.preferenceTypeSMS.FieldValue}
                                    checked={notificationPreference.preferenceTypeSMS.FieldSMSCheck}
                                    onChange={handleManageNotication}
                                />
                                <Cirrus.Checkbox
                                    label={fetchContactPreferenceForJobsLabels("Job.JobMatches.ContactPreferenceForJobs.EmailNotifications")}
                                    name='emailNotification'
                                    value={notificationPreference.preferenceTypeEmail.FieldValue}
                                    checked={notificationPreference.preferenceTypeEmail.FieldEmailCheck}
                                    onChange={handleManageNotication}
                                />
                                <Cirrus.Select
                                    className='mt-2'
                                    id='preferredEmail'
                                    label={fetchContactPreferenceForJobsLabels("Job.JobMatches.ContactPreferenceForJobs.SelectEmailForNotifications")}
                                    name='preferredEmail'
                                    onChange={handleManageNotication}
                                    options={emailList && emailList}
                                    placeholder="Primary Email"
                                />
                                <Cirrus.Button
                                    primaryButton="true"
                                    children={fetchContactPreferenceForJobsLabels("Job.JobMatches.ContactPreferenceForJobs.SaveUpdates")}
                                    className='mt-3'
                                    disabled={preferenceButton}
                                    onClick={onSubmitNotificationPreference}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <Cirrus.Checkbox
                            label={checkBoxLabel}
                            labelText={checkBoxLabel}
                            name="notification"
                            onBlur={() => { }}
                            onChange={notify}
                            defaultChecked={hasOpted}
                            removeBorder='true'
                            disabled={isCheckBoxDisabled}
                        />                        
                        <br />
                        {hasOpted &&
                            <>
                                <Cirrus.RadioButton
                                    labelText={fetchLabel("Job.JobMatches.UploadResume.Daily")}
                                    name="notification"
                                    onChange={checkedDaily}
                                    value={fetchLabel("Job.JobMatches.UploadResume.Daily")}
                                    defaultChecked={isDaily}
                                    disabled = {isNotnRadioDisabled}
                                />
                                <Cirrus.RadioButton
                                    labelText={fetchLabel("Job.JobMatches.UploadResume.Weekly")}
                                    name="notification"
                                    onChange={checkedWeekly}
                                    value={fetchLabel("Job.JobMatches.UploadResume.Weekly")}
                                    defaultChecked={!isDaily}
                                    disabled = {isNotnRadioDisabled}
                                />
                            </>
                        }
                    </div>
                </Cirrus.JobMatchSetupContainer>
            }
        </>
    )
}

export default Matchsetup;
