//-----------------------------------------------------------------------
// Copyright © 2022 ManpowerGroup. All Rights Reserved.
//-----------------------------------------------------------------------

import React, { useState, useEffect, useContext } from "react";
import * as Cirrus from "manpowergroup-cirrus";
import "./Job.css";
import { Context } from "../../common/context/Context";
import HeaderWithNavigation from "../header/headerWithNavigation";
import { JobActions } from "./JobActions";
import background from "manpowergroup-cirrus/assets/images/background.png";
import Footer from "../../common/components/footer";
import htmlParser from "html-react-parser";

import JobMatchesTab from "./JobMatchesTab";
import JobSearchTab from "./JobSearchTab";
import JobSavedTab from "./JobSavedTab";
import {
  useAppInsightsContext,
  useTrackEvent,
} from "@microsoft/applicationinsights-react-js";
import { TrackActions } from "../../common/utility/LogAction";
import { Container, Row } from "react-bootstrap";

function Job() {
  let featuresConfig = JSON.parse(
    window.sessionStorage.getItem("featuresConfig")
  );
  const context = useContext(Context);
  const [tabStatus, setTabStatus] = useState({
    matchesTab: featuresConfig?.isJobMatchFeatureAvailable,
    searchTab:
      featuresConfig?.isJobMatchFeatureAvailable === true ? false : true,
    savedjobsTab: false,
    otherJobsTab: false,
  });
  const [jobDetailsShowHide, setJobDetailsShowHide] = useState(false);
  const [masterCountryLists, setMasterCountryLists] = useState([]);
  const [cmsData, setCmsData] = useState();
  const [saveUnsaveJobId, setSaveUnsaveJobId] = useState({
    jobId: "",
    flag: false,
  });
  const [cmsJobCardContent, setCmsJobCardContent] = useState({
    pageContent: {},
  });
  const [cmsJobWidgetContent, setCmsJobWidgetContent] = useState({
    pageContent: {},
  });
  const [cmsJobMatchContent, setCmsJobMatchContent] = useState({
    title: "",
    description: "",
    tabNavigationTitle: "",
    pageContent: {},
  });
  const [cmsJobSearchContent, setCmsJobSearchContent] = useState({
    title: "",
    description: "",
    tabNavigationTitle: "",
    pageContent: {},
  });
  const [cmsJobSavedContent, setCmsJobSavedContent] = useState({
    title: "",
    description: "",
    tabNavigationTitle: "",
    pageContent: {},
  });
  const [cmsOtherJobsContent, setCmsOtherJobsContent] = useState({
    title: "",
    description: "",
    tabNavigationTitle: "",
    pageContent: {},
  });
  const [matchesTabSearchForm, setMatchesTabSearchForm] = useState({
    value: "",
    disabledSearchBtn: true,
  });
  const [quickSearchObj, setQuickSearchObj] = useState({
    keyword: "",
  });

  const [cmsJobTools, setCmsJobTools] = useState([]);
  const [otherJobSources, setOtherJobSources] = useState([]);

  const JDshowHide = (flag) => {
    setJobDetailsShowHide(flag);
  };

  const saveUnsaveFlag = (id, flag) => {
    let refSaveUnsaveJobId = JSON.parse(JSON.stringify(saveUnsaveJobId));
    refSaveUnsaveJobId.jobId = id;
    refSaveUnsaveJobId.flag = flag;
    setSaveUnsaveJobId(refSaveUnsaveJobId);
  };

  const [isResumeUploaded, setIsResumeUploaded] = useState(false);

  const appInsights = useAppInsightsContext();

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllCountryLists();
    fetchCMSData();
    TrackActions.PageViewRecord(appInsights, "Job Search");
    TrackActions.PageViewRecord(appInsights, "Job Match");
    TrackActions.PageAction("Job Search");
    TrackActions.PageAction("Job Match");
  }, [context.language]);

  const getAllCountryLists = async () => {
    JobActions.GetCountryList()
      .then((countryList) => {
        if (countryList?.data?.length > 0) {
          let data = countryList?.data.map((country) => {
            return {
              name: country?.countryName,
              value: country?.countryCode_alpha_2,
            };
          });
          setMasterCountryLists(data);
        } else {
          setMasterCountryLists([]);
        }
      })
      .catch((err) => {
        console.error("error", err);
      });
  };

  const fetchCMSData = () => {
    let refCmsJobcardContent = JSON.parse(JSON.stringify(cmsJobCardContent));
    let refCmsJobMatchContent = JSON.parse(JSON.stringify(cmsJobMatchContent));
    let refCmsJobSearchContent = JSON.parse(
      JSON.stringify(cmsJobSearchContent)
    );
    let refCmsJobSavedContent = JSON.parse(JSON.stringify(cmsJobSavedContent));
    let refCmsJobWidgetContent = JSON.parse(
      JSON.stringify(cmsJobWidgetContent)
    );
    let refCmsOtherJobsContent = JSON.parse(
      JSON.stringify(cmsOtherJobsContent)
    );
    let payload = {
      PageName: "JobSearch Pages",
    };
    JobActions.GetCMSJobs(payload).then(
      (res) => {
        if (res.status === 200) {
          let resData = res.data;
          setCmsData(resData);
          resData?.Pages.filter((x) => {
            if (x.Key === "JobSearch.JobMatches") {
              refCmsJobcardContent.pageContent =
                x.Components["JobSearch.JobCard"];
              x.Components["JobSearch.JobCard"]?.Labels.map((x) => {
                if (x.Key === "JobSearch.JobCard.LoadMore") {
                  refCmsJobcardContent["loadMore"] = x.Phrase;
                }
                if (x.Key === "JobSearch.JobCard.Loading") {
                  refCmsJobcardContent["loading"] = x.Phrase;
                }
              });
              setCmsJobCardContent(refCmsJobcardContent);

              refCmsJobMatchContent.pageContent = x.Components;
              refCmsJobMatchContent.tabNavigationTitle = x.TabNavigationTitle;
              refCmsJobMatchContent.description = x.Description;
              refCmsJobMatchContent.title = x.Title;
              setCmsJobMatchContent(refCmsJobMatchContent);
            }
            if (x.Key === "JobSearch.JobSearch") {
              refCmsJobSearchContent.pageContent = x.Components;
              refCmsJobSearchContent.tabNavigationTitle = x.TabNavigationTitle;
              refCmsJobSearchContent.description = x.Description;
              refCmsJobSearchContent.title = x.Title;
              refCmsJobSearchContent.pageContent["SupportedCurrencies"] =
                resData?.MarketDetails[0].SupportedCurrencies;
              setCmsJobSearchContent(refCmsJobSearchContent);

              let jobTools = [];
              Object.keys(refCmsJobSearchContent?.pageContent).forEach(
                (key) => {
                  if (
                    key !== "SupportedCurrencies" &&
                    key !== "JobSearch.JobMatchesPageTemplate" &&
                    key !== "CommonExceptionMessageComponent" &&
                    key !== "JobSearch.EditSavedSearch" &&
                    key !== "JobSearch.SearchJobBox" &&
                    key !== "JobSearch.SaveJobSearch" &&
                    key !== "JobSearch.YourSavedSearch" &&
                    key !== "JobSearch.SearchList" &&
                    key !== "JobSearch.CompareJobs" &&
                    key !== "JobSearch.RefineSearch"
                  ) {
                    if (key === "JobSearch.ProfileCreation") {
                      jobTools.unshift(
                        refCmsJobSearchContent?.pageContent[key]
                      );
                    } else {
                      jobTools.push(refCmsJobSearchContent?.pageContent[key]);
                    }
                  }
                }
              );
              setCmsJobTools(jobTools);
            }
            if (x.Key === "JobSearch.SavedJobs") {
              refCmsJobWidgetContent.pageContent =
                x.Components["JobSearchPages.Job.JobWidget"];
              setCmsJobWidgetContent(refCmsJobWidgetContent);

              refCmsJobSavedContent.pageContent = x.Components;
              refCmsJobSavedContent.tabNavigationTitle = x.TabNavigationTitle;
              refCmsJobSavedContent.description = x.Description;
              refCmsJobSavedContent.title = x.Title;
              setCmsJobSavedContent(refCmsJobSavedContent);
            }
            if (x.Key === "JobSearch.JobSearchLight") {
              //refCmsJobWidgetContent.pageContent = x.Components["JobSearchPages.Job.JobWidget"];
              //setCmsJobWidgetContent(refCmsJobWidgetContent);

              refCmsOtherJobsContent.pageContent = x.Components;
              refCmsOtherJobsContent.tabNavigationTitle = x.TabNavigationTitle;
              refCmsOtherJobsContent.description = x.Description;
              refCmsOtherJobsContent.title = x.Title;
              setCmsOtherJobsContent(refCmsOtherJobsContent);

              let jobSources = [];
              Object.keys(refCmsOtherJobsContent?.pageContent).forEach(
                (key) => {
                  if (
                    key !== "SupportedCurrencies" &&
                    key !== "JobSearch.JobMatchesPageTemplate" &&
                    key !== "CommonExceptionMessageComponent" &&
                    key !== "JobSearch.EditSavedSearch" &&
                    key !== "JobSearch.SearchJobBox" &&
                    key !== "JobSearch.SaveJobSearch" &&
                    key !== "JobSearch.YourSavedSearch" &&
                    key !== "JobSearch.SearchList" &&
                    key !== "JobSearch.CompareJobs" &&
                    key !== "JobSearch.RefineSearch"
                  ) {
                    if (key === "JobSearch.ProfileCreation") {
                      jobSources.unshift(
                        refCmsOtherJobsContent?.pageContent[key]
                      );
                    } else {
                      jobSources.push(refCmsOtherJobsContent?.pageContent[key]);
                    }
                  }
                }
              );
              setOtherJobSources(jobSources);
            }
          });
        }
        if (featuresConfig?.isJobMatchFeatureAvailable) {
          JobActions.ResumeUploadStatus().then(
            (resp) => {
              if (resp.status === 200 && resp?.data?.length > 0) {
                if (resp?.data[0]?.extractMatchQuery !== "") {
                  setIsResumeUploaded(true);
                } else {
                  setIsResumeUploaded(false);
                }
              } else {
                setIsResumeUploaded(false);
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const matchesTabInputHandler = (event) => {
    let refMatchesTabSearchForm = JSON.parse(
      JSON.stringify(matchesTabSearchForm)
    );
    if (event.target.value !== "") {
      refMatchesTabSearchForm.value = event.target.value;
      refMatchesTabSearchForm.disabledSearchBtn = false;
      setMatchesTabSearchForm(refMatchesTabSearchForm);
    } else {
      refMatchesTabSearchForm.value = "";
      refMatchesTabSearchForm.disabledSearchBtn = true;
      setMatchesTabSearchForm(refMatchesTabSearchForm);
    }
  };
  // const matchesTabSearchBtnHandler = () => {
  //     setQuickSearchObj({
  //         keyword: matchesTabSearchForm.value,
  //     })
  //     searchTabHandler();
  //     document.querySelector('#job-search').click();
  // }

  const matchesTabHandler = () => {
    TrackActions.PageViewRecord(appInsights, "Job Match");
    TrackActions.PageAction("Job Match");
    let refTabStatus = JSON.parse(JSON.stringify(tabStatus));
    refTabStatus.matchesTab = true;
    refTabStatus.searchTab = false;
    refTabStatus.savedjobsTab = false;
    refTabStatus.otherJobsTab = false;
    setTabStatus(refTabStatus);
    setMatchesTabSearchForm({
      value: "",
      disabledSearchBtn: true,
    });
  };
  const searchTabHandler = () => {
    let refTabStatus = JSON.parse(JSON.stringify(tabStatus));
    refTabStatus.matchesTab = false;
    refTabStatus.searchTab = true;
    refTabStatus.savedjobsTab = false;
    refTabStatus.otherJobsTab = false;
    setTabStatus(refTabStatus);
  };
  const savedjobsTabHandler = () => {
    TrackActions.PageViewRecord(appInsights, "SavedJobs");
    TrackActions.PageAction("SavedJobs");
    let refTabStatus = JSON.parse(JSON.stringify(tabStatus));
    refTabStatus.matchesTab = false;
    refTabStatus.searchTab = false;
    refTabStatus.savedjobsTab = true;
    refTabStatus.otherJobsTab = false;
    setTabStatus(refTabStatus);
    setMatchesTabSearchForm({
      value: "",
      disabledSearchBtn: true,
    });
  };
  const otherjobsTabHandler = () => {
    TrackActions.PageViewRecord(appInsights, "OtherJobs");
    TrackActions.PageAction("OtherJobs");
    let refTabStatus = JSON.parse(JSON.stringify(tabStatus));
    refTabStatus.matchesTab = false;
    refTabStatus.searchTab = false;
    refTabStatus.savedjobsTab = false;
    refTabStatus.otherJobsTab = true;
    setTabStatus(refTabStatus);
    setMatchesTabSearchForm({
      value: "",
      disabledSearchBtn: true,
    });
  };

  // const fetchMatchQuickSearchLabel = (Key) => {
  //     if (cmsJobMatchContent.pageContent["JobSearch.QuickSearch"]) {
  //         let refJobGreetingsLabel = JSON.parse(JSON.stringify(cmsJobMatchContent.pageContent["JobSearch.QuickSearch"]))
  //         let label = "";
  //         if (refJobGreetingsLabel?.Labels) {
  //             refJobGreetingsLabel?.Labels.map((x) => {
  //                 if (x.Key === Key) {
  //                     label = x.Phrase;
  //                 }
  //             });
  //         }
  //         return label;
  //     }
  // }

  return (
    <>
      <div
        style={
          jobDetailsShowHide === true
            ? { height: 0, overflow: "hidden" }
            : { height: "100%", overflow: "visible" }
        }
      >
        {tabStatus.matchesTab && isResumeUploaded && (
          <Cirrus.PageBanner
            bodyText={cmsJobMatchContent?.description}
            heading={cmsJobMatchContent?.title}
          />
        )}

        {/* {
                    tabStatus.matchesTab && isResumeUploaded &&
                    <Cirrus.SearchJobListPageBanner
                        ariaLabel={cmsJobMatchContent?.title}
                        heading={cmsJobMatchContent?.title}
                        searchButtonProps={{
                            children: fetchMatchQuickSearchLabel("JobSearch.QuickSearch.SearchBtn"),
                            size: "Small",
                            type: "button",
                            onClick: () => { matchesTabSearchBtnHandler() },
                            state: 'error',
                            errorMessage: "Allowed characters only",
                            disabled: matchesTabSearchForm.disabledSearchBtn
                        }}
                        searchInputProps={{
                            hideLabel: true,
                            icon: "search",
                            id: "Search",
                            label: "SearchBtn",
                            name: "search",
                            placeholder: fetchMatchQuickSearchLabel("JobSearch.QuickSearch.SearchBoxPlaceholder\r\n\r\n"),
                            type: "text",
                            onChange: (event) => { matchesTabInputHandler(event) }
                        }}
                    />
                } */}

        {tabStatus.matchesTab && isResumeUploaded === false && (
          <Cirrus.PageBanner
            bodyText={
              cmsJobMatchContent?.pageContent[
                "JobSearch.JobMatchesPageTemplate"
              ]?.Description
            }
            heading={
              cmsJobMatchContent?.pageContent[
                "JobSearch.JobMatchesPageTemplate"
              ]?.Title
            }
            maskImageSource={
              cmsJobSearchContent?.pageContent["JobSearch.SearchJobBox"]
                ?.MediaData?.LargeImage?.ImageUrl
            }
          />
        )}

        {tabStatus.searchTab && (
          <Cirrus.PageBanner
            bodyText={cmsJobSearchContent?.description}
            heading={cmsJobSearchContent?.title}
          />
        )}

        {tabStatus.savedjobsTab && (
          <Cirrus.PageBanner
            bodyText={cmsJobSavedContent?.description}
            heading={cmsJobSavedContent?.title}
          />
        )}
        {/* {
                    tabStatus.savedjobsTab && <Cirrus.SearchJobListPageBanner
                        ariaLabel={cmsJobSavedContent?.title}
                        heading={cmsJobSavedContent?.title}
                        searchButtonProps={{
                            children: fetchMatchQuickSearchLabel("JobSearch.QuickSearch.SearchBtn"),
                            size: "Small",
                            type: "button",
                            onClick: () => { matchesTabSearchBtnHandler() },
                            state: 'error',
                            errorMessage: "Allowed characters only",
                            disabled: matchesTabSearchForm.disabledSearchBtn
                        }}
                        searchInputProps={{
                            hideLabel: true,
                            icon: "search",
                            id: "Search",
                            label: "SearchBtn",
                            name: "search",
                            placeholder: fetchMatchQuickSearchLabel("JobSearch.QuickSearch.SearchBoxPlaceholder\r\n\r\n"),
                            type: "text",
                            onChange: (event) => { matchesTabInputHandler(event) }
                        }}
                    />
                } */}

        {tabStatus.otherJobsTab && (
          <Cirrus.PageBanner
            bodyText={cmsJobSearchContent?.description}
            heading={cmsJobSearchContent?.title}
          />
        )}
      </div>

      {featuresConfig?.isJobSearchLightFeatureAvailable === true ? (
        <Cirrus.OneColumnLayout type="wrapped">
          <Container
            fluid
            className="learning-collections all-learning-resource jobToolsWrapper"
          >
            <Row>
              <div className="assessment-card-block">
                {cmsJobTools?.map((jobTools) => {
                  return (
                    <div className="assessment-card">
                      <Cirrus.DoceboCard
                        // title={jobTools?.Title}
                        // description={jobTools?.Description}
                        title={htmlParser(jobTools ? jobTools?.Title : "")}
                        description={htmlParser(
                          jobTools ? jobTools?.Description : ""
                        )}
                        image={{
                          src: jobTools?.MediaData?.LargeImage?.ImageUrl,
                        }}
                        primaryButtonProps={{
                          children: jobTools?.Labels[0]?.Phrase,
                          size: "Large",
                          href: jobTools?.Labels[0]?.Link?.Url,
                          isAlt: true,
                          leadingIcon: "open_in_new",
                          target: "_blank",
                        }}
                        tags={""}
                        isWide={true}
                        className="jobToolCard"
                        // type="task"
                        layout="horizontal"
                      />
                    </div>
                  );
                })}
              </div>
            </Row>
          </Container>
        </Cirrus.OneColumnLayout>
      ) : (
        <>
          <div
            style={
              jobDetailsShowHide === true
                ? { height: 0, overflow: "hidden" }
                : { height: "100%", overflow: "visible" }
            }
          >
            <Cirrus.OneColumnLayout type="wrapped">
              <Cirrus.Placeholder
                style={{ float: "left", width: "100%", padding: 0 }}
              >
                <div className="jobs-tabs">
                  <Cirrus.TabbedNavigation
                    defaultSelected={
                      featuresConfig?.isJobMatchFeatureAvailable === true
                        ? "job-matches"
                        : "job-search"
                    }
                    tabs={[
                      {
                        heading: cmsJobMatchContent?.tabNavigationTitle,
                        id: "job-matches",
                        tabpanelId: "job-matches-panel",
                        onClick: () => {
                          matchesTabHandler();
                        },
                        isVisible: featuresConfig?.isJobMatchFeatureAvailable,
                      },
                      {
                        heading: cmsJobSearchContent?.tabNavigationTitle,
                        id: "job-search",
                        tabpanelId: "job-search-panel",
                        onClick: () => {
                          searchTabHandler();
                        },
                        isVisible: true,
                        // isVisible: featuresConfig?.isJobSearchFeatureAvailable
                      },
                      {
                        heading: cmsJobSavedContent?.tabNavigationTitle,
                        id: "saved-jobs",
                        tabpanelId: "saved-jobs-panel",
                        onClick: () => {
                          savedjobsTabHandler();
                        },
                        isVisible: true,
                      },
                      {
                        heading: cmsOtherJobsContent?.tabNavigationTitle,
                        id: "other-jobs",
                        tabpanelId: "other-jobs-panel",
                        onClick: () => {
                          otherjobsTabHandler();
                        },
                        isVisible:
                          featuresConfig.isJobSearchMaxFeatureAvailable,
                      },
                    ]}
                  />
                </div>
                <div className="jobs-dropdown">
                  <Cirrus.Select
                    placeholder={
                      featuresConfig?.isJobMatchFeatureAvailable === true
                        ? cmsJobMatchContent?.tabNavigationTitle
                        : cmsJobSearchContent?.tabNavigationTitle
                    }
                    options={
                      featuresConfig?.isJobMatchFeatureAvailable === true
                        ? [
                            {
                              name: cmsJobMatchContent?.tabNavigationTitle,
                              value: "job-matches",
                            },
                            {
                              name: cmsJobSearchContent?.tabNavigationTitle,
                              value: "job-search",
                            },
                            {
                              name: cmsJobSavedContent?.tabNavigationTitle,
                              value: "saved-jobs",
                            },
                            {
                              name: cmsOtherJobsContent?.tabNavigationTitle,
                              value: "other-jobs",
                            },
                          ]
                        : [
                            {
                              name: cmsJobSearchContent?.tabNavigationTitle,
                              value: "job-search",
                            },
                            {
                              name: cmsJobSavedContent?.tabNavigationTitle,
                              value: "saved-jobs",
                            },
                            {
                              name: cmsOtherJobsContent?.tabNavigationTitle,
                              value: "other-jobs",
                            },
                          ]
                    }
                    onChange={(e) => {
                      const dropDown = e.target.value;
                      if (dropDown === "job-matches") {
                        matchesTabHandler();
                      } else if (dropDown === "job-search") {
                        searchTabHandler();
                      } else if (dropDown === "saved-jobs") {
                        savedjobsTabHandler();
                      } else if (dropDown === "other-jobs") {
                        otherjobsTabHandler();
                      }
                    }}
                  />
                </div>
              </Cirrus.Placeholder>
            </Cirrus.OneColumnLayout>
          </div>
          <div
            style={
              tabStatus.matchesTab === true
                ? { display: "block", width: "100%" }
                : { display: "none" }
            }
          >
            <JobMatchesTab
              JDshowHide={JDshowHide}
              masterCountryLists={masterCountryLists}
              matchCMSData={cmsJobMatchContent}
              jobCardCMSData={cmsJobCardContent}
              searchCMSData={cmsJobSearchContent}
              tabStatus={tabStatus}
              saveUnsaveJobId={saveUnsaveJobId}
            />
          </div>
          <div
            style={
              tabStatus.searchTab === true
                ? { display: "block", width: "100%" }
                : { display: "none" }
            }
          >
            <JobSearchTab
              JDshowHide={JDshowHide}
              masterCountryLists={masterCountryLists}
              quickSearchObj={quickSearchObj}
              searchCMSData={cmsJobSearchContent}
              jobCardCMSData={cmsJobCardContent}
              saveUnsaveJobId={saveUnsaveJobId}
            />
          </div>
          <div
            style={
              tabStatus.savedjobsTab === true
                ? { display: "block", width: "100%" }
                : { display: "none" }
            }
          >
            <JobSavedTab
              JDshowHide={JDshowHide}
              savedCMSData={cmsJobSavedContent}
              tabStatus={tabStatus}
              jobCardCMSData={cmsJobCardContent}
              saveUnsaveFlag={saveUnsaveFlag}
              cmsJobWidgetContent={cmsJobWidgetContent}
            />
          </div>
          <div
            style={
              tabStatus.otherJobsTab === true
                ? { display: "block", width: "100%" }
                : { display: "none" }
            }
          >
            <Cirrus.OneColumnLayout type="wrapped">
              <Container
                fluid
                className="learning-collections all-learning-resource jobToolsWrapper"
              >
                <Row>
                  <div className="assessment-card-block">
                    {otherJobSources?.map((jobSource) => {
                      return (
                        <div className="assessment-card">
                          <Cirrus.DoceboCard
                            // title={jobSource?.Title}
                            // description={jobSource?.Description}
                            title={htmlParser(
                              jobSource ? jobSource?.Title : ""
                            )}
                            description={htmlParser(
                              jobSource ? jobSource?.Description : ""
                            )}
                            image={{
                              src: jobSource?.MediaData?.LargeImage?.ImageUrl,
                            }}
                            primaryButtonProps={{
                              children: jobSource?.Labels[0]?.Phrase,
                              size: "Large",
                              href: jobSource?.Labels[0]?.Link?.Url,
                              isAlt: true,
                              leadingIcon: "open_in_new",
                              target: "_blank",
                            }}
                            tags={""}
                            isWide={true}
                            className="jobToolCard"
                            // type="task"
                            layout="horizontal"
                          />
                        </div>
                      );
                    })}
                  </div>
                </Row>
              </Container>
            </Cirrus.OneColumnLayout>
          </div>
        </>
      )}
    </>
  );
}

export default Job;
